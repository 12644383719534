import React, { useState } from "react";
import { Image, Mask } from "antd-mobile"
import ReactPlayer from 'react-player'
import styles from './styles.module.less'

interface PosterProps {
    visible: Boolean, 
    cpNum: Number,
    sender: String,
    receiver: String,
    sceneOpenPoster: Boolean,
    sort: Number,
    url: String,
    close?: Function
}

const Scene: React.FC<PosterProps> = (props: any) => {
    const { visible, cpNum, sender, receiver, sceneOpenPoster, sort, url, close } = props
    const onClose = () => {
      close(false, sceneOpenPoster)
    }
    
    return (
        <Mask visible={visible}>
          <div className={styles.scene}>
            <div className={styles.sceneView}>
              <div className={styles.wingPuzzle}>
                <div className={styles.avatarImgList}>
                  <div className={styles.imgView}>
                    <img className={styles.img} src={sender} alt="" />
                  </div>
                  <div className={styles.imgView}>
                    <img className={styles.img} src={receiver} alt="" />
                  </div>
                  <div className={styles.cpHeader}></div>
                </div>
              </div>
              <div className={styles.value}>CP值:{cpNum}</div>
              <div className={styles.sceneContent}>
                <div className={styles.video}>
                  {/* <ReactPlayer controls playing muted={true} width='220px' height='128px' loop url={require('../../assets/40822_1721640428.mp4')} /> */}
                  {
                    // visible && <ReactPlayer controls playing muted={true} playsinline={true} width='100%' height='100%' loop url={url} />
                    visible && <ReactPlayer playing light={<img className={styles.builtIn} src={require(sort === 0 ? '../../assets/cpcover1.png' : sort === 1 ? '../../assets/cpcover2.png' : '../../assets/cpcover3.png')} alt ='缩略图' />} controls playsinline={true} width='100%' height='100%' loop url={url} />
                  }
                </div>
                <div className={styles.t1}>第{ sort + 1 }场约会</div>
                <div className={styles.t2}>{ sort === 0 ? '一起过七夕节' : sort === 1 ? '一起嗨起来' : '我们结婚啦' }</div>
              </div>
            </div>
            <div className={styles.sceneclose} onClick={() => onClose()}></div>
          </div>
        </Mask>
    )
}
export default Scene