/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
// import { Toast } from 'antd-mobile'
import { isWeixin } from '../../utils'
import WxTip from '../../components/WxTip'
import { useSearchParams } from "react-router-dom";
import { getdownload, getUserNicknameAndImg, toBroadcastByCode } from '../../services'
import styles from './styles.module.less'

const BroadcastHelp = () => {
    const [searchParams] = useSearchParams(); // 获取地址栏参数函数
    const isiOS = !navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 安卓
    const code = searchParams.get('code') // 授权码
    const id = searchParams.get('id') // 用户id
    const userDetailRefs = useRef<any>({})
    const [userDetail, setuserDetail] = useState<any>({}) // 用户信息
    const [noticeDes, setnoticeDes] = useState<string>('+10') // 提示弹出
    const [noticeText, setnoticeText] = useState<string>('助力成功，助力人气') // 提示文案
    const [noticeType, setnoticeType] = useState<boolean>(false)
    const isWeixinMark = isWeixin()

    const [showTip, setshowTip] = useState<boolean>(false)
    const [broadcastTxt, setbroadcastTxt] = useState<string>('为Ta助力')
    const [buttonLoading, setbuttonLoading] = useState<boolean>(false)
    const [url, seturl] = useState<string>('')


    /**
    * 获取用户信息
    * */
    const getUserDetail = async () => {
        const userId = id
        let res: any = await getUserNicknameAndImg({ userId })
        console.log(res, '获取用户信息')
        if (res.code === 200) {
            setuserDetail(res.data)
            userDetailRefs.current.detail = res.data
        }
    }

    const appleToBroadcast = async () => {
        const res = await toBroadcastByCode({ code, helpId: id, action: 2 })
        if (res.code === 200) {
            if (res.data === '已为Ta助力') {
                // Toast.show({
                //     icon: 'success',
                //     content: '助力成功',
                // })
                setnoticeDes('+10')
                setnoticeText('助力成功，助力人气')
                setnoticeType(true)
                setTimeout(() => {
                    setnoticeType(false)
                }, 2000);
                setbroadcastTxt('已为Ta助力')
                setbuttonLoading(false)
            }
        }
    }

    /**
     * 为他助力
     * */
    const toBroadcast = async (action) => {
        const newuserDetail = userDetailRefs.current.detail
        console.log(buttonLoading)
        if (buttonLoading) return
        if (broadcastTxt === '已为Ta助力') return
        const code = searchParams.get('code')
        const id = searchParams.get('id')
        if (!code) {
            wxOauth(newuserDetail)
            setbuttonLoading(false)
            return
        }
        let res: any = await toBroadcastByCode({ code, helpId: id, action })
        if (res.code === 200) {
            console.log(res)
            if (res.data === '已为Ta助力') {
                setbroadcastTxt('已为Ta助力')
            }
            setbuttonLoading(res.data === '已为Ta助力' ? true : false)
        } else {
            setbuttonLoading(false)
        }
    }


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const downFn = async () => {
        let res: any = await getdownload()
        console.log(res)
        if (res.code === 200) {
            seturl(res.data)
        }
    }



    /**
     * 微信授权登录
     * */
    const wxOauth = (userDetail) => {
        // let oauthUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize';
        // oauthUrl += '?appid=' + userDetail?.appid;
        // oauthUrl += '&response_type=code&scope=snsapi_userinfo';
        // oauthUrl += '&redirect_uri=' + encodeURIComponent("https://h5.itianliao.com/BroadcastHelp?id=" + userDetail.id)
        // oauthUrl += '&state=' + userDetail?.state + '#wechat_redirect';
        // https://app.itianliao.com
        const oauthUrl = process.env.BASE_URL + '/user/wx/mpLogin?url=https://h5.itianliao.com/BroadcastHelp?id=' + userDetail.id
        window.open(oauthUrl, '_blank');
    }

    useEffect(() => {
        getUserDetail()
        if (code) {
            appleToBroadcast()
        }
        console.log(buttonLoading)
        if (!isWeixinMark) {
            if (!isiOS) {
                downFn()
            }
        }
    }, [])
    const downClick = () => {
        if (isWeixinMark) {
            setshowTip(true)
        } else {
            if (!isiOS) {
                window.location.href = "https://apps.apple.com/cn/app/%E5%A4%A9%E8%81%8A/id1580695049";
            } else {
                window.location.href = url
            }
        }
    }
    return (
        <div className={styles.BroadcastHelp}>
            <div className={styles.userInfo}>
                <div className={styles.image}>
                    <img src={userDetail.avatarImg} alt="" />
                </div>
                <span>{userDetail.nickname}</span>
            </div>
            <div className={styles.textPng}>
                <img src={require('../../assets/text.png')} alt="" />
            </div>
            <div className={styles.text}>
                人生离不开贵人相助
            </div>
            <div className={styles.text}>
                请你为我助力打气，帮我成为人气主播！
            </div>
            <div className={broadcastTxt === '为Ta助力' ? styles.powerBtn : styles.butDisabled} onClick={() => toBroadcast(2)}>{broadcastTxt}</div>
            <div className={styles.fixbottom}>
                <div className={styles.left}>
                    <img src={require('../../assets/newLogo.png')} alt="" />
                    <div>
                        <span>天聊App</span>
                        <span>上天聊 越聊越精神!</span>
                    </div>
                </div>
                <div className={styles.btn} onClick={() => downClick()}>打开天聊APP</div>
            </div>
            <WxTip showTip={showTip} />

            {/* 原本弹出搬过来的 */}

            {
                noticeType &&
                <div className={styles.notice}>
                    <div>
                        <span>{noticeText}</span>
                        <span style={{
                            color: '#FFCC00'
                        }}>{noticeDes}</span>
                    </div>
                </div>
            }

        </div>
    )
}
export default BroadcastHelp;