/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DocumentTitle from 'react-document-title'
import { Image } from 'antd-mobile'
import { newYearList } from '../../services'
import styles from './styles.module.less'

interface Props {

}

interface IRequestSuccess {
    data: Object | any,
    code: Number

}
// 双旦活动
const Holiday: React.FC<Props> = () => {
    const tabList = [
        {
            label:'礼物榜单',
            value:0
        },
        {
            label:'圣诞活动',
            value:1
        },
        {
            label:'元旦活动',
            value:2
        }
    ]
    const [dataList,setdataList] = useState<any>([])
    const [active,setactive] = useState<number>(0)
    const [isFixed,setisFixed] = useState<boolean>(false)
    const getDetail = async () => {
        const res: IRequestSuccess = await newYearList({})
        if (res.code === 200) {
            res.data.forEach((item:any) =>{
                if(item.nickname.length>5) {
                    item.nickname = item.nickname.substr(0,5) + '...'
                }
            })
            setdataList(res.data)
        }
    }
    //获得页面向左、向上卷动的距离
    const getScroll = () => {
        return {
            left: window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0,
            top: window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
        };
    }
  
    const userTop = () => {
        return (
            <div className={styles.userTop}>
                <div className={styles.userList}>
                    <div>排名</div>
                    <div>
                        参赛者
                    </div>
                    <div>礼物值</div>
                </div>
                {
                    dataList.length <= 0 ? 
                    (
                        <div className={styles.countDown}>
                        <Image src={require('../../assets/countDown.png')} />
                        <div>活动即将开始<br/>敬请关注</div>
                    </div>
                    )
                    :
                    dataList?.map((item:any,index:number) => (
                        <div className={styles.userList} key={index}>
                            <div>
                                {index<=2?
                                (<Image src={require(`../../assets/${index===0?'holiday1':index===1?'holiday2':'holiday3'}.png`)} />)
                                :
                                (<span>{index+1}</span>)}
                            </div>
                            <div>
                                <img src={item.avatarImg} alt="" />
                                <span>{item.nickname}</span>
                            </div>
                            <div>{item.ranking}</div>
                        </div>
                    ))
                }
            </div>        
        )
    }
    const christmas = () => {
        return (
        <div className={styles.explain}>
            <div className={styles.time}>
                <div>活动时间：12月24日-12月30日</div>
            </div>
            <div className={styles.explainItem}>
                <div className={styles.title}>活动规则</div>
                <div className={styles.content}>
                    <div>
                        1.在天聊收到礼物，视为自动参与该活动；
                    </div>
                    <div>
                        2.收到的礼物金额越高，获得的声浪越多，明星值越高，排名就越靠前；
                    </div>
                    <div>
                        3.若活动榜单多名用户数值相同时，则先达到该数值的用户排在前。
                    </div>
                </div>
            </div>
            <div className={styles.explainItem}>
                <div className={styles.title}>活动奖励</div>
                <div className={styles.content}>
                    <div className={styles.table}>
                        <div className={styles.tableitem}>
                            <div className={styles.w40}>名次</div>
                            <div className={styles.w60}>奖励</div>
                        </div>
                        <div className={styles.tableitem}>
                            <div className={styles.w40}>第 1 名</div>
                            <div className={styles.w60}>累计收到声浪的 50%聊币</div>
                        </div>
                        <div className={styles.tableitem}>
                            <div className={styles.w40}>第 2 名</div>
                            <div className={styles.w60}>累计收到声浪的 40%聊币</div>
                        </div>
                        <div className={styles.tableitem}>
                            <div className={styles.w40}>第 3 名</div>
                            <div className={styles.w60}>累计收到声浪的 30%聊币</div>
                        </div>
                        <div className={styles.tableitem}>
                            <div className={styles.w40}>第 4-6 名</div>
                            <div className={styles.w60}>累计收到声浪的 20%聊币</div>
                        </div>
                        <div className={styles.tableitem}>
                            <div className={styles.w40}>第 7-10 名</div>
                            <div className={styles.w60}>累计收到声浪的 10%聊币</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.explainItem}>
                <div className={styles.title}>活动须知</div>
                <div className={styles.content}>
                    <div>
                    1、用户仅能使用个人有效注册账号参与此活动，不得以任何不正当手段或舞弊方式参与此活动，一经发现，本平台有权采取没收活动奖励、限制登录、封禁账号、回收声浪等措施，如因为给用户造成损失，本平台不进行任何赔偿或补偿。
                    </div>
                    <div>
                    2、违规批量注册账号领取免费聊币赠送礼物、利用平台漏洞非法获取大量免费聊币赠送礼物、鼓舞或参与其他用户利用非法手段参与活动、利用非法支付漏洞参与活动等行为不计入真实排名。
                    </div>
                    <div>
                    3、本活动不与其他活动累加，平台免费赠送聊币不参与该活动，平台活动赠送聊币不参与该活动。本活动与苹果公司无关，本活动最终解释权由天聊所有。
                    </div>
                </div>
            </div>
        </div>
        )
    }
    const newYear = () => {
        return (
        <div className={styles.explain}>
            <div className={styles.time}>
                <div>活动时间：12月31日-1月2日</div>
            </div>
            <div className={styles.explainItem}>
                <div className={styles.title}>活动规则及奖励</div>
                <div className={styles.content}>
                    <div className={styles.table}>
                        <div className={styles.tableitem}>
                            <div>累计充值人民币</div>
                            <div>充值可获 聊币</div>
                            <div>平台赠送 聊币</div>
                            <div>累计获得 聊币</div>
                            <div>实际赠送 占比</div>
                        </div>
                        <div className={styles.tableitem}>
                            <div>100-<br/>300</div>
                            <div>1000- <br/>3000</div>
                            <div>50-<br/>150</div>
                            <div>1050- <br/>3150</div>
                            <div>5%</div>
                        </div>
                        <div className={styles.tableitem}>
                            <div>301-<br/>500</div>
                            <div>3010- <br/>5000</div>
                            <div>301-<br/>500</div>
                            <div>3311- 5500</div>
                            <div>10%</div>
                        </div>
                        <div className={styles.tableitem}>
                            <div>501-<br/>1000</div>
                            <div>5010- 10000</div>
                            <div>752-<br/>1500</div>
                            <div>5762- 11500</div>
                            <div>15%</div>
                        </div>
                        <div className={styles.tableitem}>
                            <div>1001-<br/>3000</div>
                            <div>10010- 30000</div>
                            <div>2002- 6000</div>
                            <div>12012- 36000</div>
                            <div>20%</div>
                        </div>
                        <div className={styles.tableitem}>
                            <div>3001- 5000</div>
                            <div>30010- 50000</div>
                            <div>7503- 12500</div>
                            <div>37513- 62500</div>
                            <div>25%</div>
                        </div>
                        <div className={styles.tableitem}>
                            <div>5001- 7000</div>
                            <div>50010- 70000</div>
                            <div>15003- 21000</div>
                            <div>65013- 91000</div>
                            <div>30%</div>
                        </div>
                        <div className={styles.tableitem}>
                            <div>7001- 10000</div>
                            <div>70010- 100000</div>
                            <div>24504- 35000</div>
                            <div>94514- 135000</div>
                            <div>35%</div>
                        </div>
                        <div className={styles.tableitem}>
                            <div>10001- 50000</div>
                            <div>100010- 500000</div>
                            <div>40004- 200000</div>
                            <div>140014- 700000</div>
                            <div>40%</div>
                        </div>
                        <div className={styles.tableitem}>
                            <div>＞50000</div>
                            <div>＞500000</div>
                            <div>＞225000</div>
                            <div>＞720000</div>
                            <div>45%</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.explainItem}>
                <div className={styles.content}>
                    <div>
                        注：
                    </div>
                    <div>
                        本活动与苹果公司无关，最终解释权归天聊所有。
                    </div>
                </div>
            </div>
        </div>
        )
    }
    useEffect(() => {
        getDetail()
        const btnList = document.getElementById('btnList')
        const offsetTop = btnList?.offsetTop || 0
        window.onscroll = function() {
            console.log('aa',getScroll().top);
            console.log(offsetTop)
            if(getScroll().top >= offsetTop){
                setisFixed(true)
            }else{
                setisFixed(false)
            }
        }
    }, [])


    return (
        <DocumentTitle title='双旦活动'>
            <div className={styles.Holiday}>
                <div className={styles.top}>
                    <div className={styles.btnList} id="btnList" style={isFixed ? { zIndex: "999",top: "0",position: "fixed", backgroundColor: "#fff" } : {}}>
                        {
                            tabList.map((item:any) => (
                                <div onClick={()=> setactive(item.value)} 
                                key={item.value}
                                className={active === item.value ? styles.acitve : ''}>
                                    {item.label}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={styles.center}>
                    <div className={styles.centerBox}>
                        { active === 0 ? userTop() : active === 1 ? christmas() : newYear()}
                    </div>
                </div>
                <div className={styles.bottom}>
                    <Image className={styles.gif} src={require('../../assets/holidaybom.png')} />
                </div>
            </div>
        </DocumentTitle>
    )
}
export default Holiday;