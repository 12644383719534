import React, { useEffect, useState } from "react";
import { isiOS } from '../../utils'
import { Toast } from 'antd-mobile'
import { useSearchParams } from "react-router-dom";
import { getUserInfoByMpCode } from '../../services'
import copy from 'copy-to-clipboard';
import styles from './styles.module.less'
const GetOpenId = () => {
	const [searchParams] = useSearchParams(); // 获取地址栏参数函数
	const code = searchParams.get('code') // 授权码
	const [text,settext] = useState<string>('')
	const isIosMark = isiOS()
	/**
	* 获取用户信息
	* */
	const getUserDetail = async (code:String) => {
		let res: any = await getUserInfoByMpCode({ code })
		console.log(res, '获取用户信息')
		if (res.code === 200) {
			settext(res.data.openid)
		}
	}


	/**
	 * 微信授权登录
	 * */
	const wxOauth = () => {
		if(isIosMark){
			const oauthUrl = process.env.BASE_URL + '/user/wx/mpLogin?url=https://h5.itianliao.com/GetOpenId' 
			window.location.href = oauthUrl
		}else{
			const oauthUrl = process.env.BASE_URL + '/user/wx/mpLogin?url=https://h5.itianliao.com/GetOpenId' 
			window.open(oauthUrl, '_blank');
		}
	}
	// 复制
    const oncopy = () => {
        copy(text)
		Toast.show({
			content: text ? '复制成功' : '复制失败',
		})
    }
	
	useEffect(() => {
		if (code) {
			getUserDetail(code);
		}else {
			wxOauth();
		}
	}, []) //eslint-disable-line
	return (
		<>
		<div className={styles.text_}>{text}</div>
		<div className={styles.GetOpenId} onClick={oncopy}>复制</div>
		</>
	)
}
export default GetOpenId;