import React from "react";
import DocumentTitle from "react-document-title";
import styles from './styles.module.less'
const Rule = () => {
    return (
        <DocumentTitle title="聊天室人气排行榜规则">
            <div className={styles.RuleControl}>
                <div className={styles.text}>【人气榜排名】</div>
                <p>1、每日进行人气榜pk排名；</p>
                <p>2、每邀请一名好友助力，可获得人气票+10；</p>
                <p>3、邀请助力人数不限</p>
                <p>4、邀请助力人数不限</p>
                <div className={styles.text}>【规则说明】</div>
                <p>1、人气票达到200以上，且前三名主播才给予奖励；</p>
                <p>2、第1名主播奖励声浪500（50元）；</p>
                <p>3、第2名主播奖励声浪300（30元）；</p>
                <p>4、第3名主播奖励声浪200（20元）；</p>
            </div>
        </DocumentTitle>
    )
}
export default Rule;