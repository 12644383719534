/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import DocumentTitle from 'react-document-title'
import { useSearchParams } from "react-router-dom";
import { Image } from "antd-mobile"
import CanvasPoster from '../../components/reactCanvasPoster';

import styles from './styles.module.less'

const SharePoster = () => {
    const [searchParams] = useSearchParams();
    const nickname = searchParams.get("nickname") || ''
    const sceneSourceType = searchParams.get("sceneSourceType") || 0
    const code = searchParams.get("code") || ''
    // const showNaviBar = searchParams.get("showNaviBar")
    const [baseurl,setbaseurl] = useState<any>('')
    const onsuccess = (src)=>{ 
        // console.log(src)
        setbaseurl(src)
    }
    const hasQueryParams = (imageUrl: any) => {
        const url = new URL(imageUrl);
        return url.search !== '';
    }
    const avatarImg = hasQueryParams(searchParams.get("avatarImg")) ? (searchParams.get("avatarImg") + '&timestamp=' + new Date().getTime()) : (searchParams.get("avatarImg") + '?timestamp=' + new Date().getTime())
    console.log('看看咯', avatarImg)
    const isAndroid = () => {
        const u = navigator.userAgent;
        const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        //let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        return isAndroid;
    }

    const openShare = () => {
        if (isAndroid()) {
            window.android.openShare(baseurl)
          } else {
            window.webkit.messageHandlers.openShare.postMessage(baseurl)
          }
    }
    const backFun = () => {
        console.log('有没有点击')
        // window.history.go(-1)
        window.history.back()
    }
    const drawData =  {
        width: 630,
        height: 1120,
        scale: 4, // 处理模糊问题
        views: [
          // 绘制本地图片
            {
                type: 'image',
                url: require('../../assets/sharePosterBG.png'), // 远程图片可以直接改为图片地址
                top: 0,
                left: 0,
                width: 630,
                height: 1120,
            },
            {
                type: 'image',
                url: avatarImg ? avatarImg : require('../../assets/rt.png'), // 远程图片可以直接改为图片地址
                top: 90,
                left: 255,
                width: 120,
                height: 120,
                borderRadius: 120,
                borderWidth: 6,
                borderColor: '#f0efefea',
            },
            {
                type: 'image',
                url: require('../../assets/activeTit.png'), // 远程图片可以直接改为图片地址
                top: 244,
                left: 76,
                width: 478,
                height: 68
            },
            {
                type: 'image',
                url: require('../../assets/activeName.png'), // 远程图片可以直接改为图片地址
                top: 345,
                left: 152,
                width: 326,
                height: 55
            },
            {
                type: 'text',
                content: '集劳动光荣榜活动',
                fontSize: 30,
                fontWeight:'bold',
                color: '#FD2512',
                textAlign: 'center',
                top: 355,
                left: 315,
                width: 630,
                lineNum: 3,
                lineHeight: 20,
                baseLine: 'top',
            },
            {
                type: 'image',
                url: require('../../assets/shareAcitveQRcode.png'), // 远程图片可以直接改为图片地址
                top: 448,
                left: 195,
                width: 240,
                height: 240
            },
            {
                type: 'text',
                content: '扫码下载天聊',
                fontSize: 28,
                fontWeight:'400',
                color: '#ffffff',
                textAlign: 'center',
                top: 725,
                left: 315,
                width: 630,
                lineHeight: 1,
                baseLine: 'top',
            },
            {
                type: 'text',
                // content: '首页搜索【'+ (Number(sceneSourceType) === 7 ? nickname : code) +'】直播间',
                content: Number(sceneSourceType) === 7 ? ('首页搜索【' + nickname + '】直播间' ) : ('私信搜索我的天聊号：' + code),
                fontSize: 28,
                fontWeight:'400',
                color: '#ffffff',
                textAlign: 'center',
                top: 770,
                left: 315,
                width: 630,
                lineHeight: 1,
                baseLine: 'top',
            },
            {
                type: 'text',
                content: Number(sceneSourceType) === 7 ? '进入直播间和我一起欢度“五一”吧' : '找我一起欢度“五一”吧',
                fontSize: 28,
                fontWeight:'400',
                color: '#ffffff',
                textAlign: 'center',
                top: 815,
                left: 315,
                width: 630,
                lineHeight: 1,
                baseLine: 'top',
            }
        ]
    }

    return (
        <DocumentTitle title="分享海报">
            <div className={styles.SharePoster}>
                <div className={styles.header} style={isAndroid() ? {paddingTop: '30px'} : {}}>
                    <div className={styles.headerView}>
                        <div className={styles.backBox} onClick={backFun}>
                            <Image className={styles.back} src={require('../../assets/backblack.png')} />
                        </div>
                        <div className={styles.title}>分享海报</div>
                    </div>
                </div>
                <div className={styles.overlayContent}>
                    {/* overlayContent */}
                    <CanvasPoster drawData={drawData} success={onsuccess}/>
                    {
                        baseurl && <Image className={styles.cavasImg} src={baseurl} alt="" />
                    }
                </div>
                <div className={styles.shareActiveBtn} onClick={openShare}></div>
            </div>
        </DocumentTitle>
    )
}
export default SharePoster;