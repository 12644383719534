import {
    useRoutes,
} from "react-router-dom";
import './index.css'
import React from "react";
import Home from "../pages/Home";
import ChatanDshare from "../pages/ChatanDshare";
import ChatrooMshare from "../pages/ChatrooMshare";
import PrivaTeshare from "../pages/PrivaTeshare";
import Test from "../pages/Test";
import AnchorRecruitment from "../pages/AnchorRecruitment";
import AnchorSign from "../pages/AnchorSign"
import Rule from "../pages/Rule";
import RichText from "../pages/RichText";
import RewardRules from "../pages/RewardRules";
import InviteChatFriend from "../pages/InviteChatFriend";
import AtciVity from "../pages/AtciVity"
import LinkUrl from '../pages/LinkUrl'
import LinkUrlInner from '../pages/LinkUrlInner'
import ChannelDownload from '../pages/ChannelDownload'
import TianliaoShare from '../pages/TianliaoShare'
import TianliaoDownload from '../pages/TianliaoDownload'
import BroadcastHelp from '../pages/BroadcastHelp'
import ChannelDownloadOld from '../pages/ChannelDownloadOld'
import Download from '../pages/Download'
import Holiday from '../pages/Holiday'
import GetOpenId from '../pages/GetOpenId'
import OnlineNum from "../pages/OnlineNum";
import TopUpRebate from "../pages/TopUpRebate"
import NewyearList from "../pages/NewyearList"
import GiftPacks from "../pages/giftPakes"
import Mayday from "../pages/MayDay"
import SharePoster from "../pages/SharePoster"
import Qixi from "../pages/Qixi";
import SweetPalace from "../pages/SweetPalace"
const RoutesList = () => {
    // 1.聊圈朋友圈分享 已完成
    // 2.主播招募 已完成
    // 3.邀请聊友规则说明  已完成
    // 4.天聊公约 已完成
    // 5.用户协议 已完成
    // 6.隐私政策 已完成
    // 7.充值协议 已完成
    // 8.人脸认证协议 已完成
    // 9.聊天室人气排行榜规则
    // 10. 聊天室分享 已完成
    // 11.私信分享 已完成
    // 12.聊友邀请
    let routes = useRoutes([
        { path: "/", element: <Home /> },
        // 聊圈分享h5
        { path: "/ChatanDshare/:userDynamicId", element: <ChatanDshare /> },
        // 聊天室分享
        { path: "/ChatrooMshare/:rootUserId", element: <ChatrooMshare /> },
        // 私信分享
        { path: "/PrivaTeshare/:privateChatId", element: <PrivaTeshare /> },
        //  主播招募
        { path: "/AnchorRecruitment", element: <AnchorRecruitment /> }, 
        //  主播招募签约
        { path: "/AnchorSign", element: <AnchorSign /> }, 
        // 邀请好友规则
        { path: '/Rule', element: <Rule /> },
        // .聊天室人气排行榜规则
        { path: "/RewardRules", element: <RewardRules /> },
        // 邀请好友赚聊币
        { path: "/InviteChatFriend", element: <InviteChatFriend /> },
        { path: "/AtciVity", element: <AtciVity /> },
        { path: "/Test", element: <Test /> },
        {path:"/LinkUrl",element:<LinkUrl />},
        // 内容人员查看榜单
        {path:"/LinkUrlInner",element:<LinkUrlInner />},
        // 富文本
        { path: "/RichText/:code", element: <RichText /> },
        //天聊APP下载页
        {path:"/ChannelDownload",element:<ChannelDownload />},
        //来自好友的分享
        {path:"/TianliaoShare",element:<TianliaoShare />},
        {path:"/TianliaoDownload",element:<TianliaoDownload />},
        {path:"/BroadcastHelp",element:<BroadcastHelp />},
        {path:"/ChannelDownloadOld",element:<ChannelDownloadOld />},
        {path:"/Download",element:<Download />},
        {path:"/Holiday",element:<Holiday />},
        {path:"/GetOpenId",element:<GetOpenId />},

        // 
        { path:'/OnlineNum',element:<OnlineNum />},
        // 国庆充值返利
        { path:'/TopUpRebate',element:<TopUpRebate />},
        // 集龙福榜单活动
        { path:'/NewyearList',element:<NewyearList />},
        { path:'/GiftPacks',element:<GiftPacks />},
        // 劳动节活动
        { path:'/MayDay',element:<Mayday />},
        // 分享海报
        { path:'/SharePoster',element:<SharePoster />},
        // 七夕
        { path:'/Qixi',element:<Qixi />},
        { path:'/SweetPalace',element:<SweetPalace /> }
    ]);
    return routes;

};

export default RoutesList;