/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DocumentTitle from 'react-document-title'
import WxTip from "../../components/WxTip";
import { isWeixin } from '../../utils'
// getLatestVersion
// getdownload
// isiOS
import { pageViewsAdded, downl, channelPackageDownload } from '../../services'
import styles from './styles.module.less'


const Download = () => {
    const isWeixinMark = isWeixin()
    // const isIosMark = isiOS()
    const [showTip, setshowTip] = useState<boolean>(false)
    const [url, seturl] = useState<string>('')

    // 分解url参数
    const paramsString = window.location.search
    const searchParams = new URLSearchParams(paramsString);
    // url参数
    const channelCode = searchParams.get("channelCode")
    const code = searchParams.get("code")
    console.log(channelCode, code)
    const u = navigator.userAgent;
    const params: any = {
        channelCode: channelCode || 'Prod'
    }
    if (code) {
        params.code = code
    }
    /**
     * 初始化友盟
     * */
    useEffect(() => {
        console.log('我看看', process.env.UM_DOWNLOADID)
        ULink([{
            id: process.env.UM_DOWNLOADID,
            data: {
                channelCode: channelCode || ''
            }
        }]);
    }, [])

    const getpageViewsAdded = async () => {
        if ((u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) || (navigator.platform === "Win32") || (navigator.platform === "Windows")) {
            const res = await pageViewsAdded(params)
            const newparams = { channelIdentification: params.channelCode, code: params.code }
            if (!newparams.code) {
                delete newparams.code
            }
            const newres: any = await channelPackageDownload(newparams)
            console.log(newres, res)
            if (res.code === 200) {
                seturl(newres.data)
            }
        }
    }

    const downClick = async () => {
        var agent = navigator.userAgent.toLowerCase();
        console.log(agent)
        var isWin = (navigator.platform === "Win32") || (navigator.platform === "Windows");
        if (isWin) {
            await downl(params)
            window.location.href = url;
        } else {
            if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
                await downl(params)
                window.location.href = url;
            } else {
                window.location.href = "https://apps.apple.com/cn/app/%E5%A4%A9%E8%81%8A/id1580695049";
            }
        }


    }
    const androidDown = async () => {
        const res = await pageViewsAdded(params)
            const newparams = { channelIdentification: params.channelCode, code: params.code }
            if (!newparams.code) {
                delete newparams.code
            }
            const newres: any = await channelPackageDownload(newparams)
            console.log(newres, res)
            if (res.code === 200) {
                // seturl(newres.data)
                await downl(params)
                window.location.href = newres.data;
        }
    }
    const appleDown = async () => {
        window.location.href = "https://apps.apple.com/cn/app/%E5%A4%A9%E8%81%8A/id1580695049";
    }
    const IsPC = () => {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        if (u.indexOf("Safari") !== -1 && u.indexOf("Version") !== -1) flag = false
        return flag;
    }
    useEffect(() => {
        getpageViewsAdded()
    }, [])


    const weixinPage = () => (
        <div className={styles.weixinPage}>
            {/* <img className={styles.newLogo} src={require('../../assets/newlogo2.png')} alt="" />
            <div className={styles.text_warp}>
                <div className={styles.text_info}>「天聊」是一个正在崛起的精神服务社交平台，我们正在为逃离熟人社交和短视频的人群，建立一个真实的“人与人”聊天交流，为用户提供情绪价值服务的APP!</div>
                <div className={styles.text_info}>上天聊，认识新聊友，想聊就聊!  “你的倾诉有人倾听，你的意见有人共鸣”!</div>
            </div>
            <div className={styles.btn} onClick={() => wxClick()}>
                <img className={styles.icon} src={require('../../assets/d_icon.png')} alt="" />
                <span>立即下载</span>
            </div>
            <div className={styles.bottom}>
                <img className={styles.logotext} src={require('../../assets/logotext.png')} alt="" />
            </div> */}
            <div className={styles.content}>
                <div className={styles.icon}>
                    <img src={require('../../assets/sharecentericon.png')} alt=""/>
                </div>
                <div className={styles.text}>
                    <span>超1,000,000情感、知心聊友等你来聊</span>
                </div>
                <div className={styles.btnView}>
                    <div  onClick={() => wxClick()} className={styles.btn}></div>
                </div>
            </div>
            <WxTip onClose={onClose} showTip={showTip} />
        </div>
    )

    const onClose = () => {
        setshowTip(false)
    }
    const Browser = () => (
        <div className={styles.Browser}>
            <div className={styles.BrowserTop}>
                <img className={styles.logo} src={require('../../assets/newLogo.png')} alt="" />
                <div className={styles.textList}>
                    <div>天聊-精神社交APP</div>
                    <div>上天聊，越聊越精神</div>
                    {/* 直搔荐绍、聊天室、聊友群 */}
                    <div className={styles.spanTip}>
                        <span>多人聊天室</span>
                        <span>视频聊天</span>
                        <span>语音聊天</span>
                        <span>文字聊天</span>
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.title}>
                应用介绍
                </div>
                <div className={styles.text}>
                    <div>天聊是一个正在崛起的精神服务社交平台，我们正在为逃离熟人社交和短视频的人群，建立一个真实的“人与人”聊天交流，为用户提供情绪价值服务的APP!</div>
                    <div>上天聊，越聊越精神!   “你的倾诉有人倾听，你的意见有人共鸣”!</div>
                </div>
            </div>
            {
                IsPC() ? (
                    <div className={styles.singleBtn}>
                        <div className={styles.item} onClick={() => androidDown()}>
                            <img className={styles.icon} src={require('../../assets/andrioIcon.png')} alt="" />
                            <span>安卓下载</span>
                        </div>
                        <div className={styles.item} onClick={() => appleDown()}>
                            <img className={styles.icon} src={require('../../assets/appleIcon.png')} alt="" />
                            <span>苹果下载</span>
                        </div>
                    </div>
                ) : (
                    <div className={styles.dowbtn} onClick={() => downClick()}>
                        <img className={styles.icon} src={require('../../assets/touicon.png')} alt="" />
                        <span>立即下载</span>
                    </div>
                )
            }
            
            <div className={styles.SwiperBox}>
                <div className={styles.SwiperBox_item}>
                    <img src={require('../../assets/n01.jpg')} alt="" />
                </div>
                <div className={styles.SwiperBox_item}>
                    <img src={require('../../assets/n02.jpg')} alt="" />
                </div>
                <div className={styles.SwiperBox_item}>
                    <img src={require('../../assets/n03.jpg')} alt="" />
                </div>
                <div className={styles.SwiperBox_item}>
                    <img src={require('../../assets/n04.jpg')} alt="" />
                </div>
                {/* <div className={styles.SwiperBox_item}>
                    <img src={require('../../assets/d05.png')} alt="" />
                </div> */}

                {/* <img src={require('../../assets/store_01.jpg')} alt="" />
                <img src={require('../../assets/store_02.jpg')} alt="" />
                <img src={require('../../assets/store_03.jpg')} alt="" />
                <img src={require('../../assets/store_04.jpg')} alt="" />
                <img src={require('../../assets/store_05.jpg')} alt="" /> */}
            </div>
            <div className={styles.txtBom}>
                ©2023 天聊 版权所有   |  桂ICP备2023004442号-1
            </div>
        </div>
    )


    const wxClick = () => {
        setshowTip(true)
    }

    return (
        <DocumentTitle title="天聊-精神社交APP">
            <div className={styles.Download}>
                {isWeixinMark ? weixinPage() : Browser()}
            </div>
        </DocumentTitle>
    )
}
export default Download;