/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DocumentTitle from 'react-document-title'
import { Swiper, Image } from 'antd-mobile'
import { useParams } from "react-router-dom";
import { getPrivateChatCard } from '../../services'
import styles from './styles.module.less'
import Modal from '../../components/Modal'

interface Props {

}

interface IRequestSuccess {
    data: Object | any,
    code: Number

}
const ageRangeArr = [
    "",
    "60后",
    "70后",
    "80后",
    "90后",
    "00后"
  ]
  const constellationArr = [
    "用户星座",
    "未确定",
    "白羊座",
    "金牛座",
    "双子座",
    "巨蟹座",
    "狮子座",
    "处女座",
    "天秤座",
    "天蝎座",
    "射手座",
    "摩羯座",
    "水瓶座",
    "双鱼座"
  ]

const PrivaTeshare: React.FC<Props> = () => {

    const params: any = useParams();
    const [detail, setdetail] = useState<any>({})
    const [banner, setbanner] = useState<Array<string>>([]);
    const [visible, setVisible] = useState<boolean>(false)
    const { privateChatId } = params;

    const getDetail = async () => {
        const res: IRequestSuccess = await getPrivateChatCard(privateChatId)
        if (res.code === 200) {
            setbanner(res.data.showUrl)
            setdetail(res.data)

        }
    }
    useEffect(() => {
        getDetail()
    }, [])

    // 关闭模态框
    const onClose = (visible: boolean) => {
        setVisible(visible)
    }

    const items = banner.map((item: string, index: number) => (
        <Swiper.Item key={index}>
            <div className={styles.content}>
              <Image fit='contain' className={styles.Image} src={item} alt="" />

            </div>
            <div className={styles.background} style={{
                backgroundImage: `url(${item})`,
                backgroundSize: '100%',
            }} ></div>
        </Swiper.Item>
    ))
    // 右边按钮
    const btnControl = () => (
        <div className={styles.btnControl} onClick={() => setVisible(true)}>
            <div className={styles.userImg}>
                <div className={styles.userText}>
                    <Image src={require('../../assets/userBg.png')}></Image>
                </div>
                <Image fit="cover" className={styles.img} src={detail?.avatarImg} />
            </div>
       
            <div className={styles.margin} onClick={() => setVisible(true)}>
                <div className={styles.btnItem} >
                    <div className={styles.like}>
                        <Image className={styles.likeImg} src={require('../../assets/link2x.png')} />
                    </div>
                    <div className={styles.likeText}>喜欢</div>
                </div>
            </div>

            <div className={styles.margin} onClick={() => setVisible(true)}>
                <div className={styles.btnItem}>
                    <div className={styles.like}>
                        <Image className={styles.likeImg} src={require('../../assets/siliao.png')} />
                    </div>
                    <div className={styles.likeText}>私信Ta</div>
                </div>
            </div>

     
        </div>
    )
    // 底部信息
    const footControl = () => (
        <div className={styles.footControl}>
            <div className={styles.content}>
                <div className={styles.ip}>ip {detail?.address} </div>
                <div className={styles.spot}>.</div>
                <div className={styles.years}> {ageRangeArr[detail.ageRange]}</div>
                <div className={styles.spot}>.</div>
                <div className={styles.constellation}> {constellationArr[detail.constellation]}</div>
            </div>
            <div className={styles.content}>
            {detail.signature ||"这个人很懒啥也留下！"}
            </div>

        </div>
    )
    // 头部
    const headControl = ()=>(
        <div className={styles.headControl}>
        <Image className={styles.ren} src={require('../../assets/renqi.png')} />
        <div>{detail.popularity}人气</div>
    </div> 
    )

    return (
        <DocumentTitle title='私信'>
            <div className={styles.ChatanDshare}>
                <Swiper loop className={styles.Swiper}>{items}</Swiper>
                {btnControl()}
                {footControl()}
                {headControl()}
                <Modal visible={visible} onClose={onClose} />
            </div>
        </DocumentTitle>
    )
}
export default PrivaTeshare;