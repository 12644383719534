/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DocumentTitle from 'react-document-title'
import { Image } from 'antd-mobile'
import { useParams } from "react-router-dom";
import { getSharePageInfo } from '../../services'
import styles from './styles.module.less'
import Modal from '../../components/Modal'

interface Props {

}

interface IRequestSuccess {
    data: Object | any,
    code: Number

}

interface sharePageInfoProps {
    avatarImg: string
    avatarImgX1: string
    backgroundImg: string
    nickname: string
    topic: string
}

const ChatrooMshare: React.FC<Props> = () => {
    const params: any = useParams();
    const [visible, setVisible] = useState<boolean>(false)
    const [detail, setdetail] = useState<sharePageInfoProps>()
    const { rootUserId } = params;
    const getDetail = async () => {
        const res: IRequestSuccess = await getSharePageInfo(rootUserId)
        if (res.code === 200) {
            setdetail(res.data)
        }
    }
    useEffect(() => {
        getDetail()
    }, [])


    // 关闭模态框
    const onClose = (visible: boolean) => {
        setVisible(visible)
    }
    return (
        <DocumentTitle title='聊天室'>
            <div className={styles.ChatrooMshare}>
                <Image fit='cover' className={styles.bg} src={detail?.backgroundImg || require('../../assets/03.png')} />
                <div className={styles.head}>
                <div className={styles.userImg}>
                    <Image fit="cover" className={styles.img} src={detail?.avatarImg} />
                </div>
                <div className={styles.user_text}>@{detail?.nickname || '匿名用户'}</div>
                <div className={styles.user_title}>#{detail?.topic || '越聊越精神!,上天聊'}</div>
                <div className={styles.textMsg}>
                    <div className={styles.user_msg}>我在天聊创建了聊群</div>
                    <div className={styles.user_msg}>在和大家一起聊天，期待你的加入</div>
                </div>
                <div className={styles.textMsg}>
                    <div className={styles.user_msg}>想聊就聊 上天聊 </div>
                    <div className={styles.user_msg}>天聊是一款专注聊天的APP</div>
                    <div className={styles.user_msg}>以聊天的方式，获得情感治愈、情绪舒缓、失眠助眠、轻松解压、快乐表达等多元化精神需求。在天聊，每个人都能找到属于自己的精神乌托邦。 </div>
                    {/* <div className={styles.user_msg}>焦虑和抑郁，让生活变得更轻松！ </div> */}
                    <div className={styles.user_mt}>快点来聊天，一起和我聊天吧！ </div>
                </div>
                <div className={styles.foot} onClick={()=>setVisible(true)}>
                    <Image className={styles.gif} src={require('../../assets/kf.gif')} />
                    <div className={styles.footText}> 点击进去聊天室</div>
                </div>
            </div>
         
            <Modal visible={visible} onClose={onClose} />
            </div>
        </DocumentTitle>
    )
}
export default ChatrooMshare;