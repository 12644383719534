
import React from "react";
import { Mask } from "antd-mobile"
import styles from './styles.module.less'
import { getdownload } from '../../services'
interface ModalProps {
    visible: Boolean,
    onClose?: Function
}

const Modal: React.FC<ModalProps> = (props: any) => {
    const { visible, onClose } = props
    const isiOS = () => {
        let u = navigator.userAgent;
        let iOs = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端\
        return iOs;
    }

    const downApp = () => { // 下载App
  

        if (/(micromessenger)/i.test(navigator.userAgent)) {
            alert(/(micromessenger)/i.test(navigator.userAgent))
            return
        }
        if (isiOS()) {
            // console.log(window.location.href = 'tlppp://')
            // window.location.href = 'tlppp://'
            setTimeout(function () {
                window.location.href = 'https://apps.apple.com/cn/app/%E5%A4%A9%E8%81%8A/id1580695049'
            }, 1000);
        } else {
            window.location.href = 'tl://app.itianliao:80/startup'
            setTimeout(function () {
                getdownload().then((res: any) => {
                    window.location.href = res.data
                })
            }, 1000);
        }
    }
    return (
        <Mask visible={visible} onMaskClick={() => onClose(!visible)}>
            {
                /(micromessenger)/i.test(navigator.userAgent) ?
                (
                    <div className={styles.wxtip} onClick={() => onClose(!visible)}>
                        <span className={styles.wxTipIcon}></span>
                        <p className={styles.wxtipTxt}>点击右上角···<br />选择在浏览器中打开进行下载</p>
                        <div className={styles.wxtipIcon2}></div>
                        <div className={styles.wxtipIcon3}></div>                
                    </div>
                ) : (
                    <div className={styles.overlayContent}>
                        <div className={styles.title}>天聊APP</div>
                        <div className={styles.mian}>
                            <div className={styles.msg}>「天聊」是一个正在崛起的精神服务社交平台，我们正在为逃离熟人社交和短视频的人群，建立一个真实的“人与人”聊天交流，为用户提供情绪价值服务的APP！</div>
                            {/* <div className={styles.msg}>孤独、焦虑和抑郁</div> */}
                            {/* <div className={styles.msg}>让生活变得更轻松！</div> */}
                        </div>
                        <div className={styles.text}>上天聊，越聊越精神!   “你的倾诉有人倾听，你的意见有人共鸣”!</div>
                        <div className={styles.btn} onClick={downApp}>下载APP</div>
                    </div>
                )
            }
        </Mask>
    )
}
export default Modal