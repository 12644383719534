/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Image } from 'antd-mobile'
import { useSearchParams } from "react-router-dom";
import { RankingListByActivityId } from '../../services'
import styles from './styles.module.less'

export interface androidObj {
 
}

const LinkUrl = () => {
    const [list, setList] = useState<any>([]);
    const [paddingTop,setpaddingTop] = useState<any>(null)
    const headRef = useRef<any>(null)
    const userImgRef = useRef<any>(null)
    // const [height, setheight] = useState(0)
    const [data, setdata] = useState<any>([]);
    const [searchParams] = useSearchParams();
    const getRankingListByActivityId = async () => {
        const activityId: any = searchParams.get("activityId");
        if (!activityId) return
        // const res: any = await RankingListByActivityId('1704749701567397890', 20)
        const res: any = await RankingListByActivityId(activityId, 50)
        console.log(res)
        if (res.code === 200) {
            setList(res.data)
            const userList = res.data.slice(3)
            setdata(userList)
        }
    }
    const isAndroid = () => {
        const u = navigator.userAgent;
        const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        //let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        return isAndroid;
      }
    const backFun = () => {
        // if (isAndroid()) {
        //     window.android.callBack()
        // } else {
        //     window.webkit.messageHandlers.callBack.postMessage({ 'param': '' });
        // }
        window.history.back()
    }
    useEffect(() => {
        getRankingListByActivityId()
        // console.log('圣诞节', headRef?.current?.clientHeight,userImgRef?.current?.clientHeight)
        // setheight(document.documentElement.clientHeight - (headRef?.current?.clientHeight + userImgRef?.current?.clientHeight))
        if (isAndroid()) {
            setpaddingTop('30px')
        }
    }, [])
    return (
        <div className={styles.mian} >
            {/* 头部 */}
            <div ref={headRef} className={styles.safeTop} style={paddingTop ? {paddingTop: paddingTop} : {}}>
                <div className={styles.head}>
                    <div className={styles.backBox} onClick={backFun}>
                        <Image className={styles.back} src={require('../../assets/back.png')} />
                    </div>
                    <div className={styles.title}>劳动光荣榜</div>
                </div>
                <div ref={userImgRef} className={styles.userImg}>
                    <div className={styles.userImgItem}>
                        <div className={styles.avatarImg}>
                            {
                                list[1]?.avatarImg && (
                                    <img className={styles.img} src={list[1]?.avatarImg} alt="" />
                                )
                            }
                        </div>
                        <div className={styles.userName}>
                            { list[1]?.nickname }
                        </div>
                        <div className={styles.sign}>
                            NO.2
                        </div>
                    </div>
                    <div className={styles.userImgItem}>
                        <div className={styles.avatarImg}>
                            {
                                list[0]?.avatarImg && (
                                    <img className={styles.img} src={list[0]?.avatarImg} alt="" />
                                )
                            }
                        </div>
                        <div className={styles.userName}>
                            { list[0]?.nickname }
                        </div>
                        <div className={styles.sign}>
                            NO.1
                        </div>
                    </div>
                    <div className={styles.userImgItem}>
                        <div className={styles.avatarImg}>
                            {
                                list[2]?.avatarImg && (
                                    <img className={styles.img} src={list[2]?.avatarImg} alt="" />
                                )
                            }
                        </div>
                        <div className={styles.userName}>
                            { list[2]?.nickname }
                        </div>
                        <div className={styles.sign}>
                            NO.3
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <div className={styles.controller} style={{height}}> */}
            <div className={`${styles.controller} ${!data.length ? styles.flex : ''}`}>
                {/* <img className={styles.headImg} src={require('../../assets/head1.png')} alt="" /> */}
                {/* <div className={styles.title}>活动排名</div> */}
                <div className={styles.user_list}>
                {
                    data?.map((item: any, index: number) => (
                        <div className={styles.user_list_item} key={index}>
                            <div className={styles.user_list_item_left}>
                                <span>{(index + 4) <= 9 ? ('0'+ (index + 4)) : index + 4}</span>
                                <img className={styles.url} src={item.avatarImg} alt="" />
                                <span className={styles.name}>{item.nickname}</span>
                            </div>
                            <img className={styles.bbb} src={require('../../assets/maydayicon.png')} alt="" />
                        </div>
                    ))
                }
                </div>
                {
                    !data.length &&
                    <div className={styles.noData}>
                        <img className={styles.noDataImg} src={require('../../assets/nodatas.png')} alt="" />
                        <div>虚位以待</div>
                    </div>
                }
            </div>
        </div>
    )
}
export default LinkUrl