/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import DocumentTitle from 'react-document-title'
import CpPage from "../../components/CpPage"
import HeaderBeat from "../../components/HeaderBeat"
import { useSearchParams } from "react-router-dom";
import styles from './styles.module.less'
const SweetPalace = () => {
    const [searchParams] = useSearchParams();
    const both = searchParams.get("both") || false
    const [type, settype] = useState<number>(1)
    const changeType = (e) => {
        settype(e)
    }
    useEffect(() => {}, [])
    return (
        <DocumentTitle title="甜蜜殿堂">
            <div className={styles.SweetPalace}>
                {
                    type === 1 ? <CpPage /> : <HeaderBeat />
                }
                {
                    both && (
                        <div className={styles.sweetBtn}>
                            <div className={`${styles.b1} ${type === 1 ? styles.b1active : ''}`} onClick={() => changeType(1)}></div>
                            <div className={`${styles.b2} ${type === 2 ? styles.b2active : ''}`} onClick={() => changeType(2)}></div>
                        </div>
                    )
                }
            </div>
        </DocumentTitle>
    )
}
export default SweetPalace;