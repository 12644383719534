/* eslint-disable no-restricted-globals */
import React from "react";
import StatusSwitch from '../../components/StatusSwitch'
const Test = () => {

    const style = {
        color: 'red',
    }

    // const suffix = ()=>{
    //     <div className="">6666</div>
    // }

    const viewSource = () => {
        // const sourceurl = 'https://apps.apple.com/cn/app/%E5%A4%A9%E8%81%8A-%E6%83%B3%E8%81%8A%E5%B0%B1%E8%81%8A-%E4%B8%8A%E5%A4%A9%E8%81%8A/id1580695049'
        // let redirectUrl:string = sourceurl.indexOf('://') < 0 ? 'http://' + sourceurl : sourceurl;
        // redirectUrl = 'http://' + location.host + '/mp/redirect?url=' + encodeURIComponent(sourceurl);
        // 
        //  window.location.href ='itms-appss://apps.apple.com/cn/app/id1580695049'
         window.location.href = 'itms-appss://apps.apple.com/app/apple-store/id1580695049'
        //此处是关键，redirectUrl = http://mp.weixin.qq.com/mp/redirect?url=http%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fid399608199%23rd
       
    }

    return (
        <div>
            text1
            <StatusSwitch style={style} />
            <StatusSwitch />
            <img src="https://5b0988e595225.cdn.sohucs.com/images/20180722/fa85e9c37f0649b29b9cde3b73448618.gif" alt="" />
            <button onClick={viewSource}>下载</button>
        </div>
    )
}
export default Test;