/**
 * 是否是ios 设备
 * **/ 
export const isiOS = () => {
    let u = navigator.userAgent;
    let iOs = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端\
    return iOs;
}
export const isWeixin = () => {
    let u = navigator.userAgent.toLowerCase();
    let wx = u.indexOf('micromessenger') !== -1; //ios终端\
    return wx;
}

export const isIpad = () => {
    return /iPad/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
}

export const isAndroid = () => {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    //let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    return isAndroid;
}