/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import DocumentTitle from 'react-document-title'
import { Swiper, Image, PageIndicator } from 'antd-mobile'
import { useParams } from "react-router-dom";
import { getUserDynamicDetail, getPlayUrlByOnDemandId, listUserDynamicDetailMessagePage } from '../../services'
import styles from './styles.module.less'
import Modal from '../../components/Modal'

interface Props {

}

interface IRequestSuccess {
    data: Object | any,
    code: Number

}

const ChatanDshare: React.FC<Props> = () => {

    const params: any = useParams();
    const [detail, setdetail] = useState<any>({})
    const [banner, setbanner] = useState<Array<string>>([]);
    const [index_, setindex_] = useState<any>(0)
    const [visible, setVisible] = useState<boolean>(false)
    const [src, setsrc] = useState<string>('');
    const [playType, setplayType] = useState<Boolean>(false)
    const videoRef = useRef<any>()
    const timerRef = useRef<any>(null)
    const [commentList, setcommentList] = useState<Array<any>>([]);
    const { userDynamicId } = params;
    let [commentIndex, setcommentIndex] = useState(0)

    const getDetail = async () => {
        const res: IRequestSuccess = await getUserDynamicDetail(userDynamicId)
        if (res.code === 200) {
            const resj: any = await getPlayUrlByOnDemandId(res.data.onDemandId)
            setsrc(resj.data)
            setbanner(res.data.imgPath ? res.data.imgPath.split(',') : [])
            setdetail(res.data)
            console.log(detail)

        }
    }
    const next = (data: any) => {
        if (data.length <=1) return
        timerRef.current = setInterval(() => {
            if(commentIndex > data.length - 2 ) {
                commentIndex = 0
            } else {
                commentIndex++
            }
            setcommentIndex(commentIndex)
        },3000)
    }

    // 获取评论
    const getcomment = async () => {
        const res: any = await listUserDynamicDetailMessagePage(userDynamicId)
        if (res.code === 200) {
            setcommentList(res.data)
            next(res.data)
        }
    }
    useEffect(() => {
        getDetail()
        getcomment()
        return () => clearInterval(timerRef.current)
    }, [])

    // 关闭模态框
    const onClose = (visible: boolean) => {
        setVisible(visible)
    }
    // 播放
    const play = () => {
        if (!playType) {
            setplayType(true)
            videoRef.current.play()
        } else {
            setplayType(false)
            videoRef.current.pause()
        }
    }

    const items = banner.map((item: string, index: number) => (
        <Swiper.Item key={index}>
            <div className={styles.content}>
                {
                    detail.uploadType === 1 ? <video ref={videoRef} poster={item} webkit-playsinline playsInline
                        controls={false} onClick={play} src={src} loop className={styles.video}></video> : <Image fit='contain' onClick={play} className={styles.Image} src={item} alt="" />
                }

            </div>
            <div className={styles.background} style={{
                backgroundImage: `url(${item})`,
                backgroundSize: '100%',
            }} ></div>
        </Swiper.Item>
    ))
    // 右边按钮
    const btnControl = () => (
        <div className={styles.btnControl} onClick={() => setVisible(true)}>
            <div className={styles.userImg}>
                {/* <div className={styles.userText}>
                    <Image src={require('../../assets/userBg.png')}></Image>
                </div> */}
                <Image className={styles.img} src={detail?.avatarImg} />
            </div>
            <div className={styles.margin} onClick={() => setVisible(true)}>
                <div className={styles.btnItem}>
                    <div className={styles.like}>
                        <Image className={styles.likeImg} src='https://file-tianliao.oss-cn-shenzhen.aliyuncs.com/wxapp/img/assets/siliao.png' />
                    </div>
                    <div className={styles.likeText}>私信</div>
                </div>
            </div>
            <div className={styles.margin} onClick={() => setVisible(true)}>
                <div className={styles.btnItem} >
                    <div className={styles.like}>
                        <Image className={styles.likeImg} src='https://file-tianliao.oss-cn-shenzhen.aliyuncs.com/wxapp/img/assets/comment.png' />
                    </div>
                    <div className={styles.likeText}>{detail.commentNum || '评论'}</div>
                </div>
            </div>

            <div className={styles.margin} onClick={() => setVisible(true)}>
                <div className={styles.btnItem}>
                    <div className={styles.like}>
                        <Image className={styles.likeImg} src='https://file-tianliao.oss-cn-shenzhen.aliyuncs.com/wxapp/img/assets/like.png' />
                    </div>
                    <div className={styles.likeText}>{detail.likesNum || '点赞'}</div>
                </div>
            </div>

            <div className={styles.margin}>
                <div className={styles.btnItem}>
                    <div className={styles.like}>
                        <Image className={styles.likeImg} src='https://file-tianliao.oss-cn-shenzhen.aliyuncs.com/wxapp/img/assets/detailsgift.png' />
                    </div>
                    <div className={styles.likeText}>{detail.giftReceivedNum || '打赏'}</div>
                </div>
            </div>
        </div>
    )

    // 底部信息
    const footControlView = () => (
        <div className={styles.footControlView}>
            <div className={styles.commentList}>
                {
                    commentList.map((item,index) => (
                        <div className={commentIndex === index ? styles.commentListItem : styles.commentListItemNone} key={index}>
                            <Image className={styles.commentImg} src={item?.sendAvatarImg}></Image>
                            <div className={styles.commentText}>{item?.content}</div>

                            {/* <Image className={styles.commentImg}  src={item.giftImg}></Image> */}
                        </div>
                    ))
                }
            </div>
            <div className={styles.footControl}>
                <div className={styles.nickname}>
                    <span>@{detail.nickname}</span>
                    {
                        detail.address && (
                            <span className={styles.address_}>
                                <span className={styles.address}>|</span>
                                <span className={styles.address}>{detail.city}</span>
                            </span>
                        )
                    }
                </div>
                <div className={styles.content}>{detail.content}</div>
            </div>
        </div>
    )

    const indicator = () => (
        <div className={styles.PageIndicatorView}>
                <PageIndicator
                    className={styles.PageIndicator}
                    total={banner.length}
                    current={index_}
                    style={{
                        '--dot-color': 'rgba(0, 0, 0, 0.2)',
                        '--active-dot-color': '#fff',
                        '--dot-size': '8px',
                        '--active-dot-size': '8px',
                        '--dot-border-radius': '50%',
                        '--active-dot-border-radius': '8px',
                        '--dot-spacing': '8px',
                    }}
                />
            </div>
    )
    // 播放
    const playControl = () => (
        <div className={styles.play} onClick={play}>
            <Image className={styles.play_img} src={require('../../assets/play.png')} />
        </div>
    )


    return (
        <DocumentTitle title='聊圈'>
            <div className={styles.ChatanDshare}>
                {
                    detail.uploadType !== 2 ? (
                        <Swiper loop className={styles.Swiper} indicator={() => null} 
                            onIndexChange={i => {
                                setindex_(i)
                            }}
                        >{items}</Swiper>
                    ) : (
                        <div className={styles.bannerEmptyView} onClick={play}>
                            <div className={styles.bannerEmpty} style={{
                                backgroundImage: `url(${detail?.avatarImg})`
                            }}>  
                            </div>
                            <div className={styles.turntableView}>
                                <div className={styles.turntable}>
                                    <Image className={`${styles.turntableimg} ${playType && styles.musicImgRotate} ${!playType && styles.pause}`} src={detail?.avatarImg} />
                                    <Image className={styles.needle} src={require('../../assets/musicneedle.png')} />
                                </div>
                            </div>
                        </div>
                    )
                }
                {btnControl()}
                {footControlView()}
                {
                    !playType && src &&
                    playControl()
                }
                {indicator()}
                <Modal visible={visible} onClose={onClose} />

                {
                    (detail.uploadType === 0 || detail.uploadType === 2) && src &&
                    <audio controls ref={videoRef} style={{ display: 'none' }} onEnded={e => {
                        setplayType(false)
                    }}>
                        <source src={src} type="audio/mp3" />
                        <source src={src} type="audio/ogg" />
                        <embed height="100" width="100" src={src} />
                    </audio>
                }

            </div>
        </DocumentTitle>
    )
}
export default ChatanDshare;