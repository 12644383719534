/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import styles from './styles.module.less'

const ChannelDownload = () => {
    const navigate = useNavigate()
    // 分解url参数
    const paramsString = window.location.search	
	const searchParams = new URLSearchParams(paramsString);
    // url参数（渠道商编码）
	const channelCode = searchParams.getAll("channelCode")
	const code = searchParams.getAll("code")

    useEffect(()=>{
        console.log(channelCode,code)
        //
        // if (code.length) {
        //     window.location.href = './download.html?channelCode=' + channelCode[0] + '&code=' + code[0]
        // } else if(channelCode.length || code.length ) {
        //     window.location.href = './download.html?channelCode=' + channelCode[0]
        // }else{
        //     window.location.href = './download.html'
        // }
        //
        if (code.length) {
            navigate(`/Download?channelCode=${channelCode[0]}&code=${code[0]}`)
        } else if(channelCode.length || code.length ) {
            navigate(`/Download?channelCode=${channelCode[0]}`)
        }else{
            navigate('/Download')
        }
    },[])
    

    return (
        <div className={styles.ChannelDownload}>
            <div className={styles.bg}></div>
            <div className={styles.downButton}>立即下载</div>
        </div>
    )
}
export default ChannelDownload;