/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import DocumentTitle from 'react-document-title'
import styles from './styles.module.less'
import { Image } from 'antd-mobile'
interface Props {

}


const TopUpRebate: React.FC<Props> = () => {
	const [visable, setvisable] = useState(false)
	const isAndroid = () => {
		const u = navigator.userAgent;
		const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
		//let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
		return isAndroid;
	}

	const topup = () => {
		if (isAndroid()) {
			window.android.showRechargePanel()
		} else {
			window.webkit.messageHandlers.topup.postMessage('abc')
		}
	}

    // useEffect(()=>{
	// 	getParamByCode()
	// },[])

    return (
        <DocumentTitle title='喜迎国庆'>
            <div className={styles.TopUpRebate}>
				<div onClick={()=> setvisable(true)} className={styles.text}></div>
				<div className={styles.bottom}>
					<Image className={styles.img} src={require('../../assets/topupdetails.png')} />
					<i onClick={topup} className={styles.btn}/>
				</div>
				{
					visable && (
						<div className={styles.model}>
							<div className={styles.body}>
								<Image className={styles.img} src={require('../../assets/tuledet.png')} />
								<Image onClick={()=> setvisable(false)} className={styles.icon} src={require('../../assets/rulec.png')} />
							</div>
						</div>
					)
				}
			</div>
        </DocumentTitle>
    )
}
export default TopUpRebate;