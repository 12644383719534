/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Image } from 'antd-mobile'
import DocumentTitle from 'react-document-title'
import styles from './styles.module.less'
import ReactPlayer from 'react-player'
import { useSearchParams } from "react-router-dom";
import { getPlayUrlByOnDemandId } from '../../services'
interface Props {

}


const Qixi: React.FC<Props> = () => {
    const [searchParams] = useSearchParams();
    // const otherUserId = searchParams.get("otherUserId")?.toString()
    // const userId = searchParams.get("userId")?.toString()
    // const activityId = searchParams.get("activityId")?.toString()
    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const [url, setUrl] = useState<any>('')
    const getVideoUrl = async () => {
        const id: any = process.env.QIXI_ONDEMANDID
        const res = await getPlayUrlByOnDemandId(id)
        setUrl(res.data)
    }
    const link = () => {
        let url = 'http://192.168.211.187:3000/'
        if (process.env.BASE_URL === 'http://dev.app.itianliao.com') {
          url = 'http://dev.h5.itianliao.com/'
        } else if (process.env.BASE_URL === 'http://pred.app.itianliao.com') {
          url = 'http://pred.h5.itianliao.com/'
        } else {
          url = 'https://h5.itianliao.com/'
        }
        window.location.href = url + 'SweetPalace?activityId=' + searchParams.get("activityId") + '&otherUserId=' + searchParams.get("otherUserId") + '&userId=' + searchParams.get("userId") + '&both=' + true
    }
    useEffect(()=>{
        const calculateCountdown = () => {
            const futureTime = new Date('2024-08-10T00:00:00').getTime();
            const currentTime = new Date().getTime();
       
            const timeLeft = futureTime - currentTime;
       
            const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
       
            if (timeLeft >= 0) {
              setCountdown({ days, hours, minutes, seconds });
            }
        };
        calculateCountdown();
        const intervalId = setInterval(calculateCountdown, 1000);
        return () => clearInterval(intervalId);
    },[])
    useEffect(() => {
        getVideoUrl()
    }, [])
    return (
        <DocumentTitle title='七夕'>
            <div className={styles.Qixi}>
				<div className={styles.video}>
                    {/* <ReactPlayer controls playing playsinline={true} muted={true} loop width="100vw" url={url} /> */}
                    <ReactPlayer controls light={<img className={styles.builtIn} src={require('../../assets/qixicover.jpg')}/>} playsinline={true} loop width="100vw" url={url} />
				</div>
				<div className={styles.content}>
                    <div className={styles.countDown}>
                        <div className={styles.countDownList}>
                            <div className={styles.item}>
                                <div className={styles.count}>{ countdown.days }</div>
                                <div className={styles.text}>天</div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.count}>{ countdown.hours }</div>
                                <div className={styles.text}>时</div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.count}>{ countdown.minutes }</div>
                                <div className={styles.text}>分</div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.count}>{ countdown.seconds }</div>
                                <div className={styles.text}>秒</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.qixiStrategy}>
                        <Image src={require('../../assets/qixiStrategy.png')}  />
                    </div>
                    <div className={styles.qixiBtn} onClick={link}>
                        <Image className={styles.image} src={require('../../assets/qixiBtn.png')}  />
                    </div>
				</div>
			</div>
        </DocumentTitle>
    )
}
export default Qixi;