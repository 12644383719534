/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Image, Mask } from "antd-mobile"

import styles from './styles.module.less'
import { useSearchParams } from "react-router-dom";
import { getPlayUrlByOnDemandId, getCpActivityData, getuserInfo } from '../../services'
import CpPoster from "../CpPoster"
import Scene from "../scene"
import { isAndroid } from "../../utils"
const CpPage = () => {
  const [sceneOpenPoster, setsceneOpenPoster] = useState<boolean>(false) // 三场礼物都第一次送完  关闭Scene组件时打开海报
  const [searchParams] = useSearchParams();
  const [rulevisible, setrulevisible] = useState<boolean>(false)
  const [posterVisible, setposterVisible] = useState<boolean>(false)
  const [cpGiftList, setcpGiftList] = useState<any>([])
  const dataSourceRef = useRef<any>([])
  const [cpNum, setcpNum] = useState<number>(0)
  const [visible, setvisible] = useState<boolean>(false)
  const [sender, setsender] = useState<any>('')
  const [receiver, setreceiver] = useState<any>('')
  const userInfo = async () => {
    const res: any = await getuserInfo(searchParams.get("userId")?.toString())
    const res_: any = await getuserInfo(searchParams.get("otherUserId")?.toString())
    if (res.code === 200) setsender(res.data.avatarImg)
    if (res_.code === 200) setreceiver(res_.data.avatarImg)
  }
  const getcpGiftList = async () => {
    const receiveUserId = searchParams.get("otherUserId")?.toString()
    const sendUserId = searchParams.get("userId")?.toString()
    // const activityId = searchParams.get("activityId")?.toString()
    console.log(receiveUserId, sendUserId)
    const res: any = await getCpActivityData({
      receiveUserId,
      sendUserId
    })
    if (res.code === 200){
      res.data.cpGiftVoList = res.data.cpGiftVoList.map((item: any) => {
        console.log('kkkkk', item.liaoMoney / res.data.cpGiftVoList[res.data.cpGiftVoList.length - 1].liaoMoney * 100)
        return {
          ...item,
          left: item.liaoMoney / res.data.cpGiftVoList[res.data.cpGiftVoList.length - 1].liaoMoney * 100
        }
      })
      setcpGiftList(res?.data?.cpGiftVoList || [])
      dataSourceRef.current = res?.data?.cpGiftVoList || []
      setcpNum(res.data.cpNum || 0)
      const giftId = searchParams.get("giftId")?.toString()
      if (giftId) {
        const rankType = res.data.cpGiftVoList.filter((item: any) => item.giftId === giftId)[0]?.rankType -1
        if (!isNaN(rankType)) {
          setvisible(true)
          getUrl(rankType)
          setsort(rankType)
        }
      }
    }
  }
  const [sort, setsort] = useState<number>(0)
  const [url, seturl] = useState<any>('')
  // const [urlList, seturlList] = useState<Object>(['', '', ''])
  const getUrl = async (scene: number) => {
    const first: any = process.env.CPFIRST_ONDEMANDID
    const second: any = process.env.CPSECOND_ONDEMANDID
    const third: any = process.env.CPTHIRD_ONDEMANDID
    const id: any = scene === 0 ? first : scene === 1 ? second : third
      const res = await getPlayUrlByOnDemandId(id)
      if (res.code === 200) {
        seturl(res.data || '')
      }
  }
  const getVideo = async (scene: number, giftNum: number, giftId: any) => {
    
    if (giftNum > 0) {
      setvisible(true)
      getUrl(scene)
      setsort(scene)
      
    } else {
      sendGift(giftId)
    }
  }
  const getIndex = (idx: any) => {
    return dataSourceRef.current.findIndex((i) => i.giftId === idx)
  }
  const close = (a: boolean, b: boolean) => {
    // console.log('烦', a, b)
    if(b) {
      setposterVisible(true)
      setsceneOpenPoster(false)
    }
    setvisible(a)
  }
  const sendGift = (giftId: any) => {
    // console.log('赠送礼物----', giftId)
    if (isAndroid()) {
      window.android.cpSendGift(giftId)
    } else {
      window.webkit.messageHandlers.cpSendGift.postMessage(giftId)
    }
  }
  const posterClose = (posterVisible: boolean) => {
    setposterVisible(posterVisible)
  }
  useEffect(() => {
    userInfo()
  }, [])
  useEffect(() => {
    getcpGiftList()
    window.cpSendGiftSuccess = (data: any) => {
      let sort = getIndex(data)
      setsort(sort)
      if (sort === -1) return
      // console.log('????----', dataSourceRef)
      const arr = dataSourceRef.current.filter((item: any) => {
        return item.giftId !== data
      }).filter((item: any) => {
        return item.giftNum <= 0
      })
      const current = dataSourceRef.current.filter((item: any) => {
        return item.giftId === data
      })
      // console.log('kkk', current)
      if (arr.length === 0 && (current.length > 0 && current[0].giftNum === 0)) { // 三个礼物都送完 弹出cp合照
        setsceneOpenPoster(true)
      }
      setvisible(true)
      getUrl(sort)
      getcpGiftList()
    }
    return () => {
      delete window.cpSendGiftSuccess
    }
  }, [])
  return (
    <div className={styles.CpPage}>
      <div className={styles.rule} onClick={() => setrulevisible(true)}>
        活动规则
      </div>
      <div className={styles.cpPhotoTop}>
        <Image className={styles.img} src={require('../../assets/cpPhotoTop.png')} />
      </div>
      <div className={styles.parnter}>
        <div className={styles.wingView}>
          <div className={styles.imgList}>
            <img className={styles.img} src={sender} alt="" />
            <img className={styles.img} src={receiver} alt="" />
          </div>
        </div>
        <div className={styles.cpHeader}></div>
        <div className={styles.text}>
          <div>{ cpNum >= 1 ? '你们是CP搭档啦' : '你还未跟Ta结成CP' }</div>
          <div>CP值：{ cpNum }</div>
        </div>
        {
          cpGiftList.filter((item: any) => item.giftNum === 0).length === 0 && <div className={styles.viewPoster} onClick={() => setposterVisible(true)}>查看CP照</div>
        }
      </div>
      <div className={styles.sessionView}>
        <div className={styles.cpbtnView}>
          <div className={styles.cpbtn} onClick={() => sendGift(null)}></div>
        </div>
        <div className={styles.cpline}>
          <div className={styles.txt}>
            <div>送礼</div>
            <div>状态</div>
          </div>
          {
            cpGiftList.map((item: any, index: any) => (
              <div className={`${styles.cplineitem} ${item.giftNum > 0 ? styles.cplineactive : ''}`} key={index} style={{ left: item.left - 12 + '%' }}>
                <div className={styles.num}>{item.liaoMoney}{item.giftNum > 0 ? 'x' + item.giftNum : ''}</div>
              </div>
            ))
          }
        </div>
        {
          cpGiftList.length === 3 && (
            <div className={styles.sessionList}>
              <div className={styles.item} onClick={() => getVideo(0, cpGiftList[0].giftNum || 0, cpGiftList[0].giftId || '')}>
                <div className={styles.img}>
                  {
                    cpGiftList[0].giftNum <= 0 && (
                      <div className={styles.lock}>
                        <div className={styles.icon}></div>
                        <div className={styles.text}>
                          <div>送{ cpGiftList[0].liaoMoney } 组CP</div>
                          <div>享受浪漫空间</div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className={styles.item} onClick={() => getVideo(1, cpGiftList[1].giftNum || 0, cpGiftList[1].giftId || '')}>
                <div className={styles.img}>
                  {
                    cpGiftList[1].giftNum <= 0 && (
                      <div className={styles.lock}>
                        <div className={styles.icon}></div>
                        <div className={styles.text}>
                          <div>再送{ cpGiftList[1].liaoMoney } 提升CP值</div>
                          <div>享受浪漫空间</div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className={styles.item} onClick={() => getVideo(2, cpGiftList[2].giftNum || 0, cpGiftList[2].giftId || '')}>
                <div className={styles.img}>
                  {
                    cpGiftList[2].giftNum <= 0 && (
                      <div className={styles.lock}>
                        <div className={styles.icon}></div>
                        <div className={styles.text}>
                          <div>再送{ cpGiftList[2].liaoMoney } 提升CP值</div>
                          <div>手牵手场景</div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          )
        }
      </div>
      <Scene visible={visible} cpNum={cpNum} sender={sender} receiver={receiver}  sort={sort} sceneOpenPoster={sceneOpenPoster} url={url} close={close} />
      <CpPoster visible={posterVisible} sender={sender} receiver={receiver} posterClose={posterClose} />
      <Mask visible={rulevisible} onMaskClick={() => setrulevisible(false)}>
        <div className={styles.ruleMask}>
          <Image className={styles.img} src={require('../../assets/sweetRule1.png')} />
          <div className={styles.close} onClick={() => setrulevisible(false)}></div>
        </div>
      </Mask>
    </div>
  )
}
export default CpPage;