/* eslint-disable no-throw-literal */

import { Toast } from 'antd-mobile'
// import { history } from 'umi'
import { extend, ResponseError, RequestOptionsInit, Context } from 'umi-request';
/**
 * @description 定义请求成功的接口模型
 * @author xxx
 * @date 2022-07-08
 * @export
 * @interface IRequestSuccess
 * @template T  data类型, 无法指定时可传入any
 */
export interface IRequestSuccess<T> {
  code: number,
  data: T,
  message: string,
  time: number
}

/**
 * @todo
 * @description 定义请求失败时的接口模型
 * @author xxx
 * @date 2022-07-08
 * @export
 * @interface IRequestError
 */
export interface IRequestError extends Error {
  data?: any,
  message: string,
  time?: number
}



export type CtlType = 'none' | 'message'
// 根前缀请求路径

export interface IApiRequest extends RequestOptionsInit {
  ctlType?: CtlType
  // 可以用于扩展请求配置
  extendsOptions?: RequestOptionsInit,
  auth?: boolean,
  isDownload?: boolean,
}
/**
 *
 * umi-request文档 https://github.com/umijs/umi-request/blob/master/README_zh-CN.md
 *
 */
const errorMessage: any = {
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。"
};

const errorHandler = (error: ResponseError): IRequestError => {
  const { response } = error
  if (response) {
    const messageText = errorMessage[response.status]
    // message.error('http请求错误: ' + response.status + '->' + messageText, 3)
    // throw可令响应promise走catch, 如需走resolve需直接return
    throw {
      ...error,
      message: messageText
    }
  }
  throw {
    ...error,
    message: '响应超时'
  }
}

const defaultHeaders = {
  'Content-Type': 'Application/json'
}

/**
 * 配置request请求时的默认参数, 底层使用fetch进行请求
 */
const baseRequest = extend({
  timeout: 30 * 1000,
  headers: defaultHeaders,
  credentials: 'include', // 默认请求是否带上cookie
  errorHandler,
  prefix:process.env.BASE_URL,
  changeOrigin: true
});

// 请求拦截器
baseRequest.interceptors.request.use((url: string, options: RequestOptionsInit): { url: string, options: RequestOptionsInit } => {

  const headers: any = {
    ...options.headers,
  }
  const userInfo: any = sessionStorage.getItem('userInfo');
  // const newToken = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsidXNlciJdLCJwaG9uZSI6IjE3MzI5ODU4OTM1IiwidXNlcl9uYW1lIjoiMTczMjk4NTg5MzUiLCJzY29wZSI6WyJhbGwiXSwiaWQiOiIxNTkwNjAwMjkwOTU5MTAxOTU0IiwiZXhwIjoxNjgxNjI2MTM0LCJhdXRob3JpdGllcyI6WyJ1c2VyX2FsbCJdLCJqdGkiOiIzMjJmNTM5Mi00M2E3LTRjZWEtOTJlYS1iMTlmMmRjNWY3MTIiLCJjbGllbnRfaWQiOiJjbGllbnRfdXNlciJ9.EF0rHYrtKtGaKreXsbgHgROtB7GgwfdDFdD2zmA4G2rQTeIvLNriUySMNo2RMXR97QOwDPsp39NMHauqkC_6U7u3p5AR1-xQ_RcIp6Jt25Gz4yfltPDqm0SufEiMjF_T-1pArGtVb6nboL-dZ6F30zBm6kY37LsR5Z8iBSQ9XqG705DFL2Qh50bVaoNyPh7M2o9lqE_YbPXEGLklpW3f4NIVFC0Bwq0vLvVZoiEUGKnp25cHMvfRKze6htVkxpxHO4fPgcPmM8IKyoXnc6xD3pG-6vUinyQRP4SAWKmaWWUvudRDtq-BMzV-o_8L4TKfa3cuZh4kNTIX89LyDScGmA'
  const newToken ='007eJxTYDAQEd643tCv/eRVZddZoqcuH2P7oeYQWXH85pa1jDymlxMUGBJTU8xNTIzTLAxNU0xMTAwsjC1TkhONTBLNzMwSU82S1m4yTmkIZGT49sk5npGBkYEFiEF8JjDJDCZZwKQCQ5qBpYmRRZphsqGRkYmJqUWiSVpqspG5YVKagWmakVESM4OhpSEA2Tcpcw=='
  const TL_token = userInfo ? JSON.parse(userInfo).tlToken : newToken
  
  if (TL_token) {
    headers.TL_token = TL_token
  }
  return {
    // 前缀如果已经带上api, 跳过自动补前缀
    // url: url.startsWith('/api') ? url : basePrefix + url,
    url,
    options: {
      ...options,
      headers,
    },
  };
});

// 响应拦截器
baseRequest.interceptors.response.use((response: Response, options: RequestOptionsInit) => {
  return response;
});

// 请求中间件
baseRequest.use(async (ctx: Context, next: () => void) => {
  await next()
})

/**
 * 公共请求层
 */
class ApiRequest {

  createRequest<T>(url: string, options: IApiRequest = { ctlType: 'none' }): Promise<IRequestSuccess<T>> {
    return new Promise((resolve, reject) => {
      baseRequest<IRequestSuccess<T>>(url, options).then((res: any) => {
        if (options.isDownload) {
          resolve(res)
          return;
        }
        if (res.code === 401) {
          Toast.show({
            icon: 'fail',
            content: res.msg,
          })
          return;
        }
        if (res.code === 200) {
          options.ctlType === 'message' && 
          Toast.show({
            icon: 'success',
            content: res.msg,
          })
        } else {
          Toast.show({
            icon: 'fail',
            content: res.msg,
          })
          // code 非1000的抛出错误 走catch
          reject(res)
        }
        resolve(res)
      }).catch((err: IRequestError) => {
        // http错误处理， 直接透传
        reject(err)
      })
    })
  }
}
export default new ApiRequest().createRequest;
