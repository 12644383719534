/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { useParams } from "react-router-dom";
import { getSystemDataByCode } from "../../services";
import styles from './styles.module.less'
interface Props {

}



const RichText: React.FC<Props> = () => {

    const [RichTextControl, setRichTextControl] = useState<any>({})
    const [text,setText] = useState<string>('')
    const params: any = useParams();
    const { code } = params;
    // 分解url参数
    const paramsString = window.location.search
    const searchParams = new URLSearchParams(paramsString);
    // url参数
    const backgroundColor = searchParams.get("backgroundColor")
    /**
     * @see url /RichText/community_self_discipline_convention 天聊公约
     * @see url /RichText/privacy_policy 隐私政策 统一隐身协议key用 private_agreement ，生产key 不知道被谁改了 。
     * @see url /RichText/user_recharge_agreement 用户充值协议 
     * @see url /RichText/face_verification_service_protocol 人脸验证协议服务
     * @see url /RichText/user_agreement 用户充值协议  
     * @see url /RichText/community_self_discipline_convention 天聊社区自律公约
     * @see url /RichText/face_verification_service_protocol 欢迎使用实名认证服务!
     * @see url /RichText/cancellation_notice 注销须知 
     * 
     * */
    const getCode = async () => {
        const res:any = await getSystemDataByCode(code)
        if (res.code === 200) {
            setRichTextControl(res.data)
            const content = formatRichText(res.data.content)
            setText(content)
        }
    }

    useEffect(() => {
        getCode()
    }, [])

    const formatRichText = (html) => {
        let newContent = html.replace(/<img[^>]*>/gi, function(match, capture) {
            match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
            match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
            match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
            return match;
        });
        newContent = newContent.replace(/style="[^"]+"/gi, function(match, capture) {
            match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
            return match;
        });
        newContent = newContent.replace(/<br[^>]*\/>/gi, '');
        newContent = newContent.replace(/<img/gi,
            '<img style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"');
        return newContent;
    }

    return (
        <DocumentTitle title={RichTextControl?.title}>
            <div className={`${styles.RichTextControl} ${backgroundColor ? styles.RichTextSize : ''}`} style={{ background: backgroundColor ? `#${backgroundColor}` : '' }}>
                <div dangerouslySetInnerHTML={{ __html:text }}></div>
            </div>
        </DocumentTitle>
    )
}
export default RichText;
