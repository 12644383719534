/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Image } from 'antd-mobile'
import { useSearchParams } from "react-router-dom";
import DocumentTitle from 'react-document-title'
import { getuserInfo, ActivityGiftTotal, checkActivity } from '../../services'
import styles from './styles.module.less'


export interface androidObj {
 
}

const MayDay = () => {
  const [searchParams] = useSearchParams();
  const [user, setuser] = useState<any>({});
  const [paddingTop,setpaddingTop] = useState<any>(null)
  const [number, setnumber] = useState<any>('')
  const [startTime, setstartTime] = useState<string>('');
  const [endTime, setendTime] = useState<string>('');
  const [scrollTop, setscrollTop] = useState<number>(0)
  // const userId =  searchParams.get("userId")
  // const otherUserId = searchParams.get("otherUserId")
  // const activityId = searchParams.get("activityId")
  const sceneSourceType = searchParams.get("sceneSourceType")
  const isAndroid = () => {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    //let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    return isAndroid;
  }
  const userInfo = async () => {
    const userId: any = searchParams.get("userId");
    if (!userId) return
    const res: any = await getuserInfo(userId)
    console.log('myuserinfo', res)
    if (res.code === 200) {
      setuser(res.data)
    }
  }
  const getActivityGiftTotal = async () => {
    const activityId: any = searchParams.get("activityId");
    // const belongToId: any = searchParams.get("belongToId");
    const userId: any = searchParams.get("userId");
    if (!activityId || !userId) return
    const res: any = await ActivityGiftTotal(activityId, userId)
    console.log(res)
    if (res.code === 200) {
      let number = 0;
      for(let i = 0; i<res.data.length; i++){
        number += res.data[i].giftNum
      }
      setnumber(res.data.length ? number : '0')
    }
  }
  const getcheckActivity = async () => {
    const activityId: any = searchParams.get("activityId");
    if (!activityId) return
    const res: any = await checkActivity(activityId)
    if (res.code === 200) {
      // + res.data.startTime.substr(10, res.data.startTime.length)
      // + res.data.endTime.substr(10, res.data.endTime.length)
      const sTime = res.data.startTime.substr(0, 10).split("-")[0] + "年" + res.data.startTime.substr(0, 10).split("-")[1] + "月" + res.data.startTime.substr(0, 10).split("-")[2] + "日";
      const eTime = res.data.endTime.substr(0, 10).split("-")[0] + "年" + res.data.endTime.substr(0, 10).split("-")[1] + "月" + res.data.endTime.substr(0, 10).split("-")[2] + "日";
      setstartTime(sTime)
      setendTime(eTime)

    }

  }
  //   const isiOS = () => {
  //     let u = navigator.userAgent;
  //     let iOs = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端\
  //     return iOs;
  // }

  const share = () => {
    console.log(process.env.BASE_URL)
    let url = 'http://192.168.211.187:3000/'
    if (process.env.BASE_URL === 'http://dev.app.itianliao.com') {
      url = 'http://dev.h5.itianliao.com/'
    } else if (process.env.BASE_URL === 'http://pred.app.itianliao.com') {
      url = 'http://pred.h5.itianliao.com/'
    } else {
      url = 'https://h5.itianliao.com/'
    }
    // let markJson: any = {
    //   eventID: 'flagactivities_invite',
    //   jsonMap: {}
    // }
    // markJson = JSON.stringify(markJson)
    // window.android.shareAndroidBaseurl(baseurl)
    window.location.href = url + 'SharePoster?avatarImg=' + user.avatarImg + '&nickname=' + user.nickname + '&sceneSourceType=' + sceneSourceType + '&code=' + user.code
    // if (isAndroid()) {
    //   // window.webkit.messageHandlers.shareIosBaseurl.postMessage({ 'param': '' });
    //   window.android.sharePoster( '分享海报', url + "SharePoster", true, avatarImg, nickname, sceneSourceType, code )
    //   // window.android.saveStatistics('flagactivities_invite','')   // 埋点用的
    // } else {
    //   window.webkit.messageHandlers.SharePoster.postMessage({showNaviBar: true, name: '分享海报', url: url + "SharePoster", avatarImg, nickname, sceneSourceType, code })
    //   // window.webkit.messageHandlers.saveStatistics.postMessage(markJson)   // 埋点用的
    // }
  }

  const link = () => {
    let url = 'http://192.168.211.187:3000/'
    if (process.env.BASE_URL === 'http://dev.app.itianliao.com') {
      url = 'http://dev.h5.itianliao.com/'
    } else if (process.env.BASE_URL === 'http://pred.app.itianliao.com') {
      url = 'http://pred.h5.itianliao.com/'
    } else {
      url = 'https://h5.itianliao.com/'
    }
    window.location.href = url + 'LinkUrl?activityId=' + searchParams.get("activityId")
    // let markJson: any = {
    //   eventID: 'flagactivities_list',
    //   jsonMap: {}
    // }
    // markJson = JSON.stringify(markJson)
    // if (isAndroid()) {
    //   // window.webkit.messageHandlers.shareIosBaseurl.postMessage({ 'param': '' });
    //   // window.android.shareAndroidlink({ name: '集旗榜', url: '/LinkUrl' })

    //   window.android.shareAndroidlink('集旗榜', url + 'LinkUrl?activityId=' + searchParams.get("activityId"))
    //   // window.android.saveStatistics('flagactivities_list', '')  // 埋点用的
    // } else {
    //   window.webkit.messageHandlers.shareIoslink.postMessage({ name: '集旗榜', url: url + 'LinkUrl?activityId=' + searchParams.get("activityId") })
    //   // window.webkit.messageHandlers.saveStatistics.postMessage(markJson)  // 埋点用的
    // }
  }
  const backFun = () => {
    if (isAndroid()) {
      window.android.callBack()
    } else {
        window.webkit.messageHandlers.callBack.postMessage({ 'param': '' });
    }
  }

  const scrollChange = () => {
    setscrollTop(document.documentElement.scrollTop)
  }

  useEffect(() => {
    if (isAndroid()) {
      setpaddingTop('30px')
    }
    userInfo()
    getActivityGiftTotal()
    getcheckActivity()
    window.addEventListener('scroll', scrollChange, true)
    scrollChange()
    return () => {
      window.removeEventListener('scroll', scrollChange, false)
    }
  }, [])
  return (
    <DocumentTitle title='劳动光荣榜活动'>
      <div className={styles.MayDay}>
        <div className={styles.topBg}>
          <div className={`${styles.headbox} ${scrollTop > 250 ? styles.topWhite : ''}`} style={paddingTop ? {paddingTop: paddingTop} : {}}>
            <div className={styles.head}>
              <div className={styles.backBox} onClick={backFun}>
                <Image className={styles.back} src={require('../../assets/back.png')} />
              </div>
              <div className={styles.title}>劳动光荣榜活动</div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={`${styles.box} ${styles.userInfo}`}>
            <div className={styles.left}>
              <img className={styles.via} src={user.avatarImg} alt="" />
              <div className={styles.name}>
                <span>{user.nickname}</span>
                <span>收到的劳动章</span>
              </div>
            </div>
            <div className={styles.right}>
              <Image className={styles.icon} src={require('../../assets/maydayicon.png')} />
              <div className={styles.num}>+{number || 0}</div>
            </div>
          </div>
          <div className={`${styles.box} ${styles.boxicon} ${styles.details}`}>
            <div className={styles.tit}>
              活动周期
            </div>
            <div className={`${styles.text} ${styles.textcenter}`}>
              {startTime}-{endTime}
            </div>
            <div className={styles.tit}>
              活动规则
            </div>
            <div className={styles.text}>
            1、您在直播间/私聊内累计收到的劳动章礼物数量，达到任何1个梯度档位，可获得对应档位的礼品奖励（礼品奖励见下表）。 
            <br />
            低档位是集满38个劳动章礼物（获得7天全网大哥大特权）；高档位是集满99个劳动章礼物（再获得30天全网大哥大特权）。
            <br />
            如果收到的劳动章礼物超出本次活动最高档位(99个)， 不会再额外奖励。
            </div>
            <Image className={styles.jifugiftbg} src={require('../../assets/maydaygiftbg.png')} />
            <div className={styles.text}>
              2、二个档位的礼品奖励
            </div>
            <div className={styles.tableBox}>
              <div className={styles.table_}>
                <ul>
                  <li>
                    <div className={styles.nowrap}>集劳动章总量(个)</div>
                    <div>礼品奖励</div>
                    <div>图片</div>
                  </li>
                  <li>
                    <div>99</div>
                    <div>
                      <div>送30天全网大哥大特权</div>
                      <div className={styles.red}>(在全网享有专属身份，彰显与众不同)</div>
                    </div>
                    <div>
                      <Image className={styles.img} src={require('../../assets/qwdgd.png')} />
                    </div>
                  </li>
                  <li>
                    <div>38</div>
                    <div>
                      <div>送7天全网大哥大特权</div>
                      <div className={styles.red}>(在全网享有专属身份，彰显与众不同)</div>
                    </div>
                    <div>
                      <Image className={styles.img} src={require('../../assets/qwdgd.png')} />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.text}>
              3、活动奖品按礼品类型特定发放时间
            </div>
            <div className={`${styles.text} ${styles.textsm}`}>
              a.如果累计集满了38个，获得7天全网大哥大特权，统计后提前发送；
            </div>
            <div className={`${styles.text} ${styles.textsm}`}>
              b.如果累计集满了99个，再获得30天全网大哥大特权；
            </div>
            <div className={`${styles.text} ${styles.textsm}`}>
              c. 如果您不想要特权身份，可以点击搜索-输入“天聊小助手”官方账号昵称；
            </div>
          </div>
        </div>
        <div className={styles.bottomBtn}>
          <div onClick={link}></div>
          <div onClick={share}></div>
        </div>
      </div>
    </DocumentTitle>
  )
}
export default MayDay;