import React from "react"
import styles from './styles.module.less'
export interface WxtipProps {
    showTip: Boolean,
    newYear?: Boolean,
    onClose?: Function
}

const WxTip: React.FC<WxtipProps> = (props) => {
    const { showTip , newYear = false,onClose} = props
    const Close = ()=>{
        onClose && onClose()
    }
    const TipView = () => (
        <div className={styles.wxtip} onClick={()=> Close()}>
            <span className={styles.wxTipIcon}></span>
            <p className={styles.wxtipTxt}>点击右上角···<br />选择在浏览器中打开进行下载</p>
            <div className={!newYear ? styles.wxtipIcon2 : styles.wxtipIconNewYear}></div>
            <div className={styles.wxtipIcon3}></div>                
        </div>
    )
    return (
        <div>
            { showTip && TipView() }
        </div>
    )
}

// WxTip.defaultProps = {showTip:false}

export default WxTip