import request from '../utils/request';

/**
 * 聊圈详情
 * */
export const getUserDynamicDetail = (userDynamicId: string) => {
    return request(`/user/dynamic/getUserDynamicDetail?userDynamicId=${userDynamicId}&type=1`, {
        method: 'POST',
    })
}
/**
 * 获取app下载地址
 * */
export const getdownload = () => {
    return request(`/system/ApplicationVersioning/download`, {
        method: 'GET',
    })
}

/**
 * 获取视频播放地址
 * */
export const getPlayUrlByOnDemandId = (onDemandId:string) => {
    return request(`/dynamic/getPlayUrlByOnDemandId?onDemandId=${onDemandId}`, {
        method: 'GET',
    })
}


/**
 * 获取视频播放地址
 * */
 export const getSharePageInfo = (rootUserId:string) => {
    return request(`/chatRoom/room/getSharePageInfo?rootUserId=${rootUserId}`, {
        method: 'GET',
    })
}

/**
 * 获取视频播放地址
 * */
 export const getPrivateChatCard = (privateChatId:string) => {
    return request(`/user/privateChat/getPrivateChatCard?privateChatId=${privateChatId}`, {
        method: 'GET',
    })
}



/**
 * 参数管理
 * */ 
export const getSystemParamByCode = (data:Object)=>{
    return request('/system/param/listSystemParam',{
        method:'POST',
        data
    })
}
/**
 * 获取评论
 * */ 
export const listUserDynamicDetailMessagePage = (userDynamicId:string)=>{
    return request(`/user/dynamic/listUserDynamicDetailMessagePage?userDynamicId=${userDynamicId}&currentPage=1&pageSize=2`,{
        method:'POST',
    })
}

/**
 * 获取富文本配置
 * */ 
export const getSystemDataByCode = (code:string)=>{
    return request(`/system/systemData/getSystemDataByCode?code=${code}`,{
        method:'POST',
    })
}

/**
 * 邀请聊币走马灯接口
 * */ 
export const listInvitationUsersForCarousel = (userId:any)=>{
    return request(`/user/invitationRelation/listInvitationUsersForCarousel?userId=${userId || ''}`,{
        method:'get',
    })
}

/**
 * 邀请好友列表
 * */ 
export const getlistInvitationUsers = (userId:any,currentPage:number,pageSize:number)=>{
    return request(`/user/invitationRelation/listInvitationUsers?userId=${userId}&currentPage=${currentPage}&pageSize=${pageSize}`,{
        method:'POST',
    }) 
}

/**
 * 邀请好友记录
 * */ 
export const shareFriendsRedPacketReceiveRecord = (userId:any,currentPage:number,pageSize:number)=>{
    return request(`/user/redPacketReceiveRecord/shareFriendsRedPacketReceiveRecord?userId=${userId}&currentPage=${currentPage}&pageSize=${pageSize}`,{
        method:'get',
    }) 
}

export const getuserInfo = (userId:any)=>{
    return request(`/user/itUserInfo?userId=${userId}`,{
        method:'POST',
    }) 
}

export const ActivityGiftTotal = (activityId:any,userId:any)=>{
    return request(`/system/activity/getActivityGiftTotal?activityId=${activityId}&belongTo=1&userId=${userId}`,{
        method:'GET',
    }) 
}

export const checkActivity = (activityId:any)=>{
    return request(`/system/activity/checkActivity?activityId=${activityId}`,{
        method:'GET',
    }) 
}

export const RankingListByActivityId = (activityId:any,size:any)=>{
    return request(`/system/activity/getRankingListByActivityId?activityId=${activityId}&size=${size}`,{
        method:'GET',
    }) 
}

export const channelPackageDownload = (params:any)=>{
    return request(`/system/ChannelManagement/channelPackageDownload`,{
        method:'GET',
        params
    }) 
}

export const pageViewsAdded = (params)=>{
    return request(`/system/ChannelManagement/pageViewsAdded`,{
        method:'POST',
        params
    }) 
}

export const pageViewsAdded_ = (data:object)=>{
    return request(`/admin/system/ChannelManagement/pageViewsAdded`,{
        method:'POST',
        data
    }) 
}

export const downl = (params)=>{
    return request(`/admin/system/ChannelManagement/downl`,{
        method:'POST',
        params
    }) 
}

export const getPersonalDetailsByInviteCode = (invite_code:any)=>{
    return request(`/user/getPersonalDetailsByInviteCode?inviteCode=${invite_code}`,{
        method:'POST',
    }) 
}

export const getLatestVersion = ()=>{
    return request(`/system/ApplicationVersioning/getLatestVersion`,{
        method:'GET',
    }) 
}

export const getUserNicknameAndImg = (params:object) => {
    return request(`/chatRoom/help/getUserNicknameAndImg`,{
        method:'GET',
        params
    })
}

export const toBroadcastByCode = (params:object) => {
    return request(`/chatRoom/help/toBroadcastByCode`,{
        method:'GET',
        params
    })
}
export const getOnlineNum = (params?:object) => {
    return request(`/admin/user/online/getOnlineNum`,{
        method:'GET',
        params
    })
}

export const getOfficialCustomer = () => {
    return request(`/user/getOfficialCustomer`,{
        method:'GET'
    })
}

export const newYearList = (params:object) => {
    return request(`/wallet/newYearList/getList`,{
        method:'GET',
        params
    })
}

export const getUserInfoByMpCode = (params:object) => {
    return request(`/user/wx/getUserInfoByMpCode`,{
        method:'GET',
        params
    })
}

export const getRtcToken = (params:any) => {
    return request(`/referrer/referrerRoom/getRtcToken`,{
        method:'GET',
        params
    })
}


export const getUserCardOfImg = (params?:any)=>{
    return request(`/user/getUserCardOfImg `,{
        method:'GET',
        params
    })
}

export const getWxJsapiSignature = ()=>{
    return request(`/login/getWxJsapiSignature`,{
        method:'POST'
    }) 
}
export const shareFriendsRedPacketReceiveRecordTotal = (params:any) => {
    return request(`/user/redPacketReceiveRecord/shareFriendsRedPacketReceiveRecordTotal`,{
        method:'GET',
        params
    })
}

export const listRechargeSetting = (data:Object)=>{
    return request('/wallet/listRechargeSetting',{
        method:'POST',
        params: data
    })
}

export const getCpActivityData = (data:Object)=>{
    return request('/user/activityGiftInfoUserChat/getCpActivityData',{
        method:'POST',
        params: data
    })
}

export const getLoveActivityData = (data:Object)=>{
    return request('/user/activityGiftInfoUserChat/getLoveActivityData',{
        method:'POST',
        params: data
    })
}

export const getLoveActivityGift = (data:Object)=>{
    return request('/user/activityGiftInfoUserChat/getLoveActivityGift',{
        method:'POST',
        params: data
    })
}