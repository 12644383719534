/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Image } from 'antd-mobile'
import DocumentTitle from 'react-document-title'
import { listRechargeSetting } from '../../services'
import styles from './styles.module.less'



const GiftPacks = () => {
  const [total, settotal] = useState<number>(0)
  const [giftarr, setgiftarr] = useState<any>([])
  const [dataSource, setDataSource] = useState<any>([])
  const [paddingTop,setpaddingTop] = useState<any>(null)
  const [active, setactive] = useState<number>(0)
  const [activeitem, setactiveitem] = useState<any>({})
  const isAndroid = () => {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    //let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    return isAndroid;
  }

  const backFun = () => {
    if (isAndroid()) {
      window.android.callBack()
    } else {
        window.webkit.messageHandlers.callBack.postMessage({ 'param': '' });
    }
  }

  const getlist = (item: any) => {
    const arr = item.rechargeActivityGiftInfo.map((item_: any, index_: number) => {
      return {
        name: item_.name + ' 1个',
        liaoMoney: item_.liaoMoney,
        type: 'gift',
        imgPath: item_.imgPath
      }
    })
    if (item.rechargeLiaoMoney > 0) {
      arr.unshift({
        name: '聊币 ' + item.rechargeLiaoMoney + '个',
        liaoMoney: item.rechargeLiaoMoney,
        type: 'liaoMoney',
        giveLiaoMoney: item.giveLiaoMoney
      })
    }
    let total = 0
    arr.forEach(item_ => {
      total += item_.liaoMoney
    })
    if (item.giveLiaoMoney) total += item.giveLiaoMoney
    settotal(total)
    setgiftarr(arr)
  }

  const tab = (item: any,index: any) => {
    setactive(index)
    setactiveitem(item)
    getlist(item)
  }

  const getNow = () => {
    const objJson = JSON.stringify(activeitem)
    if (isAndroid()) {
      window.android.giftPacksPay(objJson)
    } else {
      window.webkit.messageHandlers.giftPacksPay.postMessage(objJson)
    }
  }

  const getData = async (type: number) => {
    const res = await listRechargeSetting({rechargeType: type, rechargeLabel: 7})
    if (res.code === 200) {
      setDataSource(res.data)
      setactiveitem(res.data[0])
      getlist(res.data[0])
    }
  }

  useEffect(() => {
    if (isAndroid()) {
      setpaddingTop('30px')
      getData(1)
    } else {
      getData(2)
    }
    
  }, [])
  return (
    <DocumentTitle title='充值大礼包'>
      <div className={styles.GiftPacks}>
        <div className={styles.giftPacksbg}>
          <div className={styles.headbox} style={paddingTop ? {paddingTop: paddingTop} : {}}>
            <div className={styles.head}>
              <div className={styles.backBox} onClick={backFun}>
                <Image className={styles.back} src={require('../../assets/back.png')} />
              </div>
              <div className={styles.title}>充值大礼包</div>
            </div>
          </div>
        </div>
        <div className={styles.tab}>
          <div className={styles.tabbox}>
            {
              dataSource.map((item: any, index: number) => (
                <div className={`${styles.tablist} ${active === index ? styles.acitve : ''}`} key={index} onClick={()=> tab(item,index)}>
                  <i></i>
                  <span>{ item.rechargeRmb }元</span>
                </div>
              ))
            }
          </div>
          <div className={styles.tabtext}>
            充{ activeitem.rechargeRmb }元获得价值 <span>{ total }</span> 币大礼包
          </div>
        </div>
        <div className={styles.giftList}>
          {
            giftarr.map((item: any, index: number) => (
              <div className={styles.item} key={index}>
                <Image className={styles.img} src={item.type === 'gift' ? item.imgPath : require('../../assets/jifucoin.png')} />
                <span>{ item.name }</span>
              </div>
            ))
          }
        </div>
        <div className={styles.board}>
          <span>1.充值活动获得的聊币自动发放至自己账户的钱包；</span>
          <span>2.充值活动获得的礼物自动下发至礼物面板的“背包”；</span>
        </div>
        <div className={styles.giftPicksNow} onClick={()=> getNow()}></div>
      </div>
    </DocumentTitle>
  )
}
export default GiftPacks;