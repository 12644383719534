/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Image } from 'antd-mobile'
import { useSearchParams } from "react-router-dom";
import DocumentTitle from 'react-document-title'
import { getuserInfo, ActivityGiftTotal, checkActivity } from '../../services'
// import Poster from '../../components/Poster'
import styles from './styles.module.less'


export interface androidObj {
 
}

const AtciVity = () => {
  const [searchParams] = useSearchParams();
  const [user, setuser] = useState<any>({});
  const [paddingTop,setpaddingTop] = useState<any>(null)
  const [number, setnumber] = useState<any>('')
  const [startTime, setstartTime] = useState<string>('');
  const [endTime, setendTime] = useState<string>('');
  // const [visible, setVisible] = useState<boolean>(true)
  const userInfo = async () => {
    const userId: any = searchParams.get("userId");
    if (!userId) return
    const res = await getuserInfo(userId)
    console.log(res, 'res')
    if (res.code === 200) {
      setuser(res.data)
    }
  }
  const getActivityGiftTotal = async () => {
    const activityId: any = searchParams.get("activityId");
    // const belongToId: any = searchParams.get("belongToId");
    const userId: any = searchParams.get("userId");
    if (!activityId || !userId) return
    const res: any = await ActivityGiftTotal(activityId, userId)
    console.log(res)
    if (res.code === 200) {
      let number = 0;
      for(let i = 0; i<res.data.length; i++){
        number += res.data[i].giftNum
      }
      setnumber(res.data.length ? number : '0')
    }
  }
  const getcheckActivity = async () => {
    const activityId: any = searchParams.get("activityId");
    if (!activityId) return
    const res: any = await checkActivity(activityId)
    if (res.code === 200) {
      // + res.data.startTime.substr(10, res.data.startTime.length)
      // + res.data.endTime.substr(10, res.data.endTime.length)
      const sTime = res.data.startTime.substr(0, 10).split("-")[0] + "年" + res.data.startTime.substr(0, 10).split("-")[1] + "月" + res.data.startTime.substr(0, 10).split("-")[2] + "日";
      const eTime = res.data.endTime.substr(0, 10).split("-")[0] + "年" + res.data.endTime.substr(0, 10).split("-")[1] + "月" + res.data.endTime.substr(0, 10).split("-")[2] + "日";
      setstartTime(sTime)
      setendTime(eTime)

    }

  }
  //   const isiOS = () => {
  //     let u = navigator.userAgent;
  //     let iOs = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端\
  //     return iOs;
  // }

  const isAndroid = () => {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    //let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    return isAndroid;
  }
  // const share = () => {
  //   let markJson: any = {
  //     eventID: 'flagactivities_invite',
  //     jsonMap: {}
  //   }
  //   // markJson = JSON.stringify(markJson)
  //   // window.android.shareAndroidBaseurl(baseurl)
  //   if (isAndroid()) {
  //     // window.webkit.messageHandlers.shareIosBaseurl.postMessage({ 'param': '' });
  //     window.android.shareAndroidBaseurl()
  //     window.android.saveStatistics('flagactivities_invite','')
  //   } else {
  //     window.webkit.messageHandlers.shareIoscallBack.postMessage({ 'param': '' })
  //     window.webkit.messageHandlers.saveStatistics.postMessage(markJson)
  //   }
  // }

  // const link = () => {
  //   console.log(process.env.BASE_URL)
  //   let url = ''
  //   if (process.env.BASE_URL === 'http://dev.app.itianliao.com') {
  //     url = 'http://dev.h5.itianliao.com/'
  //   } else if (process.env.BASE_URL === 'http://pred.app.itianliao.com') {
  //     url = 'http://pred.h5.itianliao.com/'
  //   } else {
  //     url = 'https://h5.itianliao.com/'
  //   }
  //   let markJson: any = {
  //     eventID: 'flagactivities_list',
  //     jsonMap: {}
  //   }
  //   // markJson = JSON.stringify(markJson)
  //   if (isAndroid()) {
  //     // window.webkit.messageHandlers.shareIosBaseurl.postMessage({ 'param': '' });
  //     // window.android.shareAndroidlink({ name: '集旗榜', url: '/LinkUrl' })

  //     window.android.shareAndroidlink('集旗榜', url + 'LinkUrl?activityId=')
  //     window.android.saveStatistics('flagactivities_list', '')
  //   } else {
  //     window.webkit.messageHandlers.shareIoslink.postMessage({ name: '集旗榜', url: url + 'LinkUrl?activityId=' })
  //     window.webkit.messageHandlers.saveStatistics.postMessage(markJson)
  //   }
  // }
  const backFun = () => {
    if (isAndroid()) {
      window.android.callBack()
    } else {
        window.webkit.messageHandlers.callBack.postMessage({ 'param': '' });
    }
  }
  useEffect(() => {
    if (isAndroid()) {
      setpaddingTop('30px')
    }
    userInfo()
    getActivityGiftTotal()
    getcheckActivity()
  }, [])
  return (
    <DocumentTitle title='国庆集国旗'>
      <div className={styles.AtciVity}>
        {/* 头部 */}
        <div className={styles.safeTop} style={paddingTop ? {paddingTop: paddingTop} : {}}>
          <div className={styles.head}>
            <div className={styles.backBox} onClick={backFun}>
              <Image className={styles.back} src={require('../../assets/back.png')} />
            </div>
            <div className={styles.title}>国庆集国旗</div>
          </div>
        </div>
        {/* 内容 */}
        <div className={styles.content}>
          <div className={styles.img1}>
            <Image src={require('../../assets/ngqbg1.png')} />
          </div>
          <div className={styles.userInfo}>
            <div className={styles.left}>
              <img className={styles.via} src={user.avatarImg} alt="" />
              <div className={styles.name}>
                <span>{user.nickname}</span>
                <span>收到的国旗</span>
              </div>
            </div>
            <div className={styles.right}>
              <Image className={styles.icon} src={require('../../assets/WechatIMG637@2x.png')} />
              <div className={styles.num}>+{number || 0}</div>
            </div>
          </div>
          <div className={styles.details}>
            <div className={styles.titleicon}>
              <Image className={styles.icon} src={require('../../assets/atcivitytitle.png')} />
            </div>
            <div className={styles.theme}>上天聊送国旗，为祖国庆生</div>
            <div className={styles.tit}>
              活动规则
            </div>
            <div className={styles.text}>
              1. 本次活动有二档奖励梯度，针对全平台用户开放；<br/>
              2. 活动期间您在私聊/直播间 <span className={styles.high}>送出或收到</span> 国旗礼物，数量达到任何1个梯度档位，都可获得对应档位的礼品奖励（礼品奖励见下表）；<br/>
              3. 第一档：集满38个国旗礼物（获得7天全网大哥大特权）；<br/>
              4. 第二档：集满128个国旗礼物（再获得30天全网大哥大特权）。<br/>
              5. 如果收到的国旗礼物超出本次活动最高档位(128个)， 不会再额外奖励。
            </div>
            <div className={styles.phoneimg}>
              <Image className={styles.img} src={require('../../assets/atcvityphone.png')} />
            </div>
            <div className={styles.tit}>
              活动周期
            </div>
            <div className={styles.text}>
              {startTime}-{endTime}
            </div>
            <div className={styles.tit}>
              礼品奖励
            </div>
            <div className={`${styles.text} ${styles.textleft}`}>
              <div className={styles.table_}>
                <ul>
                  <li>
                    <div>
                      <span>集国旗</span>
                      <span>总量(个)</span>
                    </div>
                    <div>
                      礼品奖励
                    </div>
                    <div>
                      图片
                    </div>
                  </li>
                  <li>
                    <div>
                      128
                    </div>
                    <div>
                      <span className={styles.w}>送30天全网大哥大特权</span>
                      <span className={`${styles.feat} ${styles.w}`}>(在全网享有专属身份，彰显与众不同)</span>
                    </div>
                    <div>
                      <Image className={styles.img} src={require('../../assets/dgd.png')} />
                    </div>
                  </li>
                  <li>
                    <div>
                      38
                    </div>
                    <div>
                      <span className={styles.w}>送7天全网大哥大特权</span>
                      <span className={`${styles.feat} ${styles.w}`}>(在全网享有专属身份，彰显与众不同)</span>
                    </div>
                    <div>
                      <Image className={styles.img} src={require('../../assets/dgd.png')} />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`${styles.text} ${styles.textleft}`}>
              <div>
                备注<br/>
                1. 定期统计给您发放奖励；<br/>
                2.如果您不想要礼物，可以点击搜索，联系“天聊小助手”官方账号昵称；
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.bottomBtn}>
          <div onClick={link}></div>
          <div onClick={share}></div>
        </div> */}
      </div>
    </DocumentTitle>
  )
}
export default AtciVity;