/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Image } from 'antd-mobile'
import DocumentTitle from 'react-document-title'
import styles from './styles.module.less'
import { getOfficialCustomer } from '../../services'
import CanvasPoster from '../../components/reactCanvasPoster'
interface Props {

}


const AnchorRecruitment: React.FC<Props> = () => {
	const [collapse, setCollapse] = useState<boolean>(false)
	const isiOS = () => {
        let u = navigator.userAgent;
        let iOs = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端\
        return iOs;
    }
	const [drawData, setDrawData] = useState<any>({})
	const [posterVisible, setPosterVisible] = useState<boolean>(false)
	// const serviceList = [
	// 	{
	// 		avatarImg: 'http://file-tianliao-dev.oss-cn-shenzhen.aliyuncs.com/portrait/2023/02/17/1676617628051_10001%20(3).jpg?x-oss-process=style/resize_w100px_jpg'
	// 	},
	// 	{
	// 		avatarImg: 'http://file-tianliao-dev.oss-cn-shenzhen.aliyuncs.com/portrait/2023/02/17/1676617628051_10001%20(3).jpg?x-oss-process=style/resize_w100px_jpg'
	// 	},
	// 	{
	// 		avatarImg: 'http://file-tianliao-dev.oss-cn-shenzhen.aliyuncs.com/portrait/2023/02/17/1676617628051_10001%20(3).jpg?x-oss-process=style/resize_w100px_jpg'
	// 	},
	// 	{
	// 		avatarImg: 'http://file-tianliao-dev.oss-cn-shenzhen.aliyuncs.com/portrait/2023/02/17/1676617628051_10001%20(3).jpg?x-oss-process=style/resize_w100px_jpg'
	// 	},
	// 	{
	// 		avatarImg: 'http://file-tianliao-dev.oss-cn-shenzhen.aliyuncs.com/portrait/2023/02/17/1676617628051_10001%20(3).jpg?x-oss-process=style/resize_w100px_jpg'
	// 	},
	// 	{
	// 		avatarImg: 'http://file-tianliao-dev.oss-cn-shenzhen.aliyuncs.com/portrait/2023/02/17/1676617628051_10001%20(3).jpg?x-oss-process=style/resize_w100px_jpg'
	// 	},
	// 	{
	// 		avatarImg: 'http://file-tianliao-dev.oss-cn-shenzhen.aliyuncs.com/portrait/2023/02/17/1676617628051_10001%20(3).jpg?x-oss-process=style/resize_w100px_jpg'
	// 	},
	// 	{
	// 		avatarImg: 'http://file-tianliao-dev.oss-cn-shenzhen.aliyuncs.com/portrait/2023/02/17/1676617628051_10001%20(3).jpg?x-oss-process=style/resize_w100px_jpg'
	// 	},
	// 	{
	// 		avatarImg: 'http://file-tianliao-dev.oss-cn-shenzhen.aliyuncs.com/portrait/2023/02/17/1676617628051_10001%20(3).jpg?x-oss-process=style/resize_w100px_jpg'
	// 	},
	// 	{
	// 		avatarImg: 'http://file-tianliao-dev.oss-cn-shenzhen.aliyuncs.com/portrait/2023/02/17/1676617628051_10001%20(3).jpg?x-oss-process=style/resize_w100px_jpg'
	// 	}
	// ]
	const [serviceList, setServiceList] = useState<any>([])
	const getServiceList = async () => {
		const res = await getOfficialCustomer()
		if (res.code === 200) setServiceList(res.data)
	}

	const onsuccess = (src)=>{ 
		console.log('有没有过来')
		setPosterVisible(false)
        if (isiOS()) {
            window.webkit.messageHandlers.sendBaseurl.postMessage(src)
        } else {
            window.android.sendBaseurl(src)
        }
    }
	const isAndroid = () => {
		const u = navigator.userAgent;
		const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
		//let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
		return isAndroid;
	}
	const CollapseFun = () => {
		setCollapse(collapse ? false : true)
	}
	const sign = (item: any) => {
		const userInfo = {
			rcUserCode: item.rcUserCode,
			userId: item.id
		}
		const str = JSON.stringify(userInfo)
		if (isAndroid()) {
			window.android.contantService(str)
		} else {
			window.webkit.messageHandlers.contantService.postMessage(str)
		}
	}


    useEffect(()=>{
		window.openPoster = (data: any) => {
			console.log('给原生的方法-------++++', data)
            setDrawData({
                width: 540,
                height: 1022,
                scale: 2, // 处理模糊问题
				views: [
					// 绘制本地图片
					{
					  type: 'image',
					  url: require('../../assets/AnchorRecruitmentPoster.png'), // 远程图片可以直接改为图片地址
					  top: 0,
					  left: 0,
					  width: 540,
					  height: 1022,
					},
					{
					   type: 'qcode',
					   text: data,
					   top: 685,
					   left: 68,
					   width: 156,
					   height: 156,
					},
					{
					  type: 'image',
					  url: require('../../assets/AnchorRecruitmentCode2.png'),
					  top: 685,
					  left: 312,
					  width: 156,
					  height: 156,
				   }
				]
            })
            setPosterVisible(true)
		}
		getServiceList()
		return () => {
			delete window.openPoster
		}
	},[])

    return (
        <DocumentTitle title='主播招募'>
            <div className={styles.AnchorRecruitment}>
				<Image className={styles.RecruitPoster} src={require('../../assets/RecruitPoster.jpg')} />
				<div className={styles.boardList}>
					<div className={styles.title}>01 私聊主播新职业</div>
					<div className={styles.text}>
						天聊是个聊天社交平台，每天都有海量人群上天聊希望找人聊天解闷，释放情绪和压力。
					</div>
					<div className={styles.text}>
						私聊主播就是为用户提供好情绪价值，把用户聊高兴、哄开心了就能做好聊天主播。
					</div>
				</div>
				<div className={styles.boardList}>
					<div className={styles.title}>02 自由兼职次日结薪，轻松月入过万</div>
					<div className={styles.text}>
						随时随地时间自由，想聊就聊。一边交朋友一切兼职赚钱两全其美。
					</div>
					<div className={styles.text}>
						只要肯努力，每日轻松赚500至1000多，别人能做到你也应该行。当日赚次日后台提现，轻松月入过万。
					</div>
				</div>
				<div className={styles.boardList}>
					<div className={styles.title}>03 继视频主播之后，私聊主播更易赚钱更火爆</div>
					<div className={styles.text}>
						不用销售、也不用自己找用户。像日常微信联系朋友一样与用户私聊，分别为文字聊天、拨打语音或视频聊天三种方式。
					</div>
					<div className={styles.text}>
						接受用户信息与用户聊天 或主动与平台海量用户聊天，做私聊主播就是这么简单。
					</div>
				</div>
				<div className={styles.boardList}>
					<div className={styles.title}>04 收益分成高、提现方便快捷</div>
					<div className={styles.text}>
						收益来自用户礼物打赏，高达50%礼物分成折算提现，及时查看每一笔收益，当日系统自动结算，次日后台提现，天天有钱赚。
					</div>
				</div>
				<div className={styles.boardList}>
					<div className={styles.title}>05 新手上路有人带，赚钱成功更容易</div>
					<div className={styles.text}>
						初来乍到新手，如何争取第一个月6000-8000元营收，第二个月过万收入? 有专人带你和培训很重要。
					</div>
				</div>
				<div className={styles.serviceView}>
					<div className={styles.title}>客服号:</div>
					<div className={`${styles.serviceList} ${collapse ? styles.serviceListOn : ''}`}>
						{
							serviceList.map((item: any, index: number) => (
								<div className={styles.item} key={index} onClick={()=> sign(item)}>
									<Image className={styles.avatarImg} src={item.avatarImg} />
									<div className={styles.who}>
										<div className={styles.icon}></div>
										<div>客服{index + 1}</div>
									</div>
								</div>
							))
						}
					</div>
					{
						serviceList.length >= 5 && (
							<div className={styles.collapse}>
								<div className={styles.collapseView} onClick={() => CollapseFun()}>
									<div>{ collapse ? '收起' : '展开更多客服' }</div>
									<div className={`${styles.icon} ${collapse ? styles.iconOn : ''}`}></div>
								</div>
							</div>
						)
					}
				</div>
				{
                    posterVisible ? <CanvasPoster drawData={drawData} success={onsuccess}/> : null
                }
			</div>
        </DocumentTitle>
    )
}
export default AnchorRecruitment;