


import React, { useState, useEffect } from "react";
import { Mask,Image } from "antd-mobile"
import { useSearchParams } from "react-router-dom";
import { getLoveActivityGift } from '../../services'
import styles from './styles.module.less'

interface ModalProps {
    visible: Boolean, 
    onClose?: Function
}

const Poster: React.FC<ModalProps> = (props: any) => {
    const { visible, onClose } = props
    const [searchParams] = useSearchParams();
    const [type, settype] = useState<Number>(1)
    const [dataSource, setDataSource] = useState<any>([])
    const getDataSource = async () => {
        const otherUserId = searchParams.get("otherUserId")?.toString()
        const userId = searchParams.get("userId")?.toString()
        const res: any = await getLoveActivityGift({
            receiveUserId: type === 1 ? userId : otherUserId,
            sendUserId: type === 1 ? otherUserId : userId
        })
        if (res.code === 200) setDataSource(res.data || [])
    }
    useEffect(() => {
        getDataSource()
    },[type])
    return (
        <Mask visible={visible} onMaskClick={() => onClose(!visible)}>
          <div className={styles.HeaderBeatDetails}>
            <div className={styles.details}>
                <div className={styles.icon} onClick={() => onClose(false)}></div>
                <div className={styles.btnList}>
                    <div className={`${styles.item} ${type === 1 ? styles.active : ''}`} onClick={() => settype(1)}>我收到的</div>
                    <div className={`${styles.item} ${type === 2 ? styles.active : ''}`} onClick={() => settype(2)}>我送出的</div>
                </div>
                {
                    dataSource.length > 0 ? (
                        <div className={styles.list}>
                            {
                                dataSource.map((item: any, index: number) => (
                                    <div className={styles.item} key={index}>
                                        <div className={styles.left}>
                                            <Image className={styles.img} src={item.imgPath} />
                                            <div>{ item.name + ' x' + item.giftNum }</div>
                                        </div>
                                        <div>{ item.loveValue + '℃' }</div>
                                    </div>
                                ))
                            }
                        </div>
                    ) : (
                        <div className={styles.empty}>
                            {
                                dataSource.length === 0 && (
                                    <div>
                                        {
                                            type === 1 ? '你还没有收到过礼物哦~' : '快去送出第一份礼物吧~'
                                        }
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
          </div>
        </Mask>
    )
}
export default Poster