/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Image, Mask } from "antd-mobile"

import styles from './styles.module.less'
import { getLoveActivityData } from '../../services'
import { useSearchParams } from "react-router-dom";
import { isAndroid } from "../../utils"
import HeaderBeatDetails from "../../components/HeaderBeatDetails"
const HeaderBeat = () => {
  const [searchParams] = useSearchParams();
  const [detailvisible, setdetailvisible] = useState<boolean>(false)
  const [rulevisible, setrulevisible] = useState<boolean>(false)
  // const receiveUserId = searchParams.get("otherUserId")?.toString()
  // const sendUserId = searchParams.get("userId")?.toString()
  const otherUserId = searchParams.get("otherUserId")?.toString()
  const userId = searchParams.get("userId")?.toString()
  // const activityId = searchParams.get("activityId")?.toString()
  const [dataSource, setDataSource] = useState<any>([])
  const dataSourceRef = useRef<any>([])
  const [loveNum, setLoveNum] = useState<number>(0)
  const getDataSource = async () => {
    // console.log('???', otherUserId, userId)
    const res: any = await getLoveActivityData({
      receiveUserId: otherUserId,
      sendUserId: userId
    })
    if (res.code === 200) {
      res?.data?.loveGiftVos.sort((a, b) => a.loveValue - b.loveValue)
      setDataSource(res?.data?.loveGiftVos || [])
      dataSourceRef.current = res?.data?.loveGiftVos || []
      setLoveNum(res.data.loveNum || 0)
    }
  }
  const getIndex = (idx: any) => {
    return dataSourceRef.current.findIndex((i) => i.giftId === idx)
  }
  const sendGift = (giftId: any) => {
    if (isAndroid()) {
      window.android.headerBeatSendGift(giftId)
    } else {
      window.webkit.messageHandlers.headerBeatSendGift.postMessage(giftId)
    }
  }
  const open = () => {
    console.log('有没有')
    setdetailvisible(true)
  }
  const onClose = (data) => {
    setdetailvisible(false)
  }
  useEffect(() => {
    getDataSource()
    window.HeadersSendGiftSuccess = (data: any) => {
      console.log('礼物赠送成功-----', data)
      if (getIndex(data) === -1) return
      getDataSource()
    }
    return () => {
      delete window.HeadersSendGiftSuccess
    }
  }, [])
  return (
    <div className={styles.HeaderBeat}>
       <div className={styles.rule} onClick={() => setrulevisible(true)}>
        活动规则
      </div>
      <div className={styles.bg}></div>
      <div className={styles.HeaderBeatView}>
        <div className={styles.content}>
          <div className={styles.topTit}></div>
          <div className={styles.topText}>提升你的心动温度，领取七夕定制尊贵特权</div>
        </div>
        <div className={styles.thermometerView}>
          <div className={styles.thermometerViews}>
            <div className={styles.icon}></div>
            {/* <div className={styles.thermometerLeft}></div> */}
            <div className={styles.thermometer}>
              <div className={styles.dotView}>
                <div className={styles.dotList}>
                  {
                    Array.from({length: 11},(_, index) => (<div key={index} className={styles.dot} style={{left: index * 10 + '%'}}></div>))
                  }
                </div>
                <div className={`${styles.dotList} ${styles.dotListlast}`}>
                  {
                    Array.from({length: 11},(_, index) => (<div key={index} className={styles.dot} style={{left: index * 10 + '%'}}></div>))
                  }
                </div>
                <Image className={styles.scale} src={loveNum >= 100 ? require('../../assets/headerBeat-full.png') : require('../../assets/headerBeat-unfull.png')} style={{ left: (loveNum >= 100 ? 100 : loveNum) + '%' }} />
              </div>
            </div>
          </div>
          <div className={styles.tip}>
            <Image className={styles.tip1} src={require('../../assets/headerbeat-tip1.png')} />
            <Image className={styles.tip2} src={require('../../assets/headerbeat-tip2.png')} />
          </div>
        </div>
        <div className={styles.cheats}>
          <div className={styles.textview}>
            <div>送礼会提升你对Ta的心动温度</div>
            <div className={styles.details} onClick={() => open()}>互动详情</div>
          </div>
          <div className={styles.giftList}>
            {
              dataSource.map((item: any, index: number) => (
                <div className={styles.item} key={index}>
                  <div className={styles.left}>
                    <Image className={styles.img} src={item.imgPath} />
                    <div className={styles.txt}>
                      <div className={styles.t1}>送出1个{item.name}礼物</div>
                      <div className={styles.t2}>每次可提升{item.loveValue}℃</div>
                    </div>
                  </div>
                  <div className={styles.btn} onClick={() => sendGift(item.giftId)}>
                    去送礼
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <Mask visible={rulevisible} onMaskClick={() => setrulevisible(false)}>
        <div className={styles.ruleMask}>
          <Image className={styles.img} src={require('../../assets/sweetRule2.png')} />
          <div className={styles.close} onClick={() => setrulevisible(false)}></div>
        </div>
      </Mask>
      <HeaderBeatDetails visible={detailvisible} onClose={onClose} />
    </div>
  )
}
export default HeaderBeat;