/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect,useState } from "react";
import DocumentTitle from 'react-document-title'
import styles from './styles.module.less'
import {getSystemParamByCode} from '../../services'
interface Props {

}


const AnchorRecruitment: React.FC<Props> = () => {
	const [rcUserCode,setrcUserCode] = useState<string>('')
	const isAndroid = () => {
		const u = navigator.userAgent;
		const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
		//let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
		return isAndroid;
	}

	const  getParamByCode = async()=>{
		const Dictionaries = ["custom_rc_user_code"]
		const res:any = await getSystemParamByCode(Dictionaries)
		console.log('???+++', res)
		if (res.code === 200) {
			res.data.forEach((item:any)=>{
				if(item.code === "custom_rc_user_code"){
					setrcUserCode(item.paramValue)
				}
			})
		}
	}

	const sign = () => {
		const userInfo = {
			rcUserCode: rcUserCode,
			userId: '285006'
		}
		const str = JSON.stringify(userInfo)
		console.log('签约噢',userInfo)
		if (isAndroid()) {
			window.android.anchorSign(str)
		} else {
			window.webkit.messageHandlers.anchorSign.postMessage(str)
		}
	}

    useEffect(()=>{
		getParamByCode()
	},[])

    return (
        <DocumentTitle title='主播招募'>
            <div className={styles.AnchorRecruitment}>
				<div className={styles.topimg}>
					<img className={styles.image_} src={require('../../assets/recruitTop2.png')} />
				</div>
				<div className={styles.board}>
					<div className={styles.boardView}>
						<div className={styles.title}>
							<b>上</b><b>天</b><b>聊</b><b>，</b><b>越</b><b>聊</b><b>越</b><b>精</b><b>神</b>
						</div>
						<div className={styles.inside}>
							<div className={styles.insidItem}>
								<div className={styles.arrow}>
									<img src={require('../../assets/arrows.png')} />
								</div>
								<div className={styles.hint}>
									<div className={styles.l}>
										01
									</div>
									<div className={styles.r}>
										聊天主播
									</div>
								</div>
								<div className={styles.insideItemContent}>
									<div className={styles.tit}>
										岗位福利:
									</div>
									<div className={styles.text}>
										50%礼物分成+平台高流水奖励+精准流量扶持+专业运营孵化
									</div>
									<div className={styles.tit}>
										岗位介绍及要求:
									</div>
									<ul className={styles.ullist}>
										<li>
											兼职全职均可，无才艺经验要求，公司提供培训 (免费)；
										</li>
										<li>
											维护用户的关系，主动与在线用户互动聊天。
										</li>
										<li>
											根据自身特质发挥自己的特长、临场应变能力强。
										</li>
										<li>
										    直播地点时间自由，不需要任何设备，有手机在家躺着办公都没问题！
										</li>
									</ul>
								</div>
							</div>
							<div className={styles.insidItem}>
								<div className={styles.arrow}>
									<img src={require('../../assets/arrows.png')} />
								</div>
								<div className={styles.hint}>
									<div className={styles.l}>
										02
									</div>
									<div className={styles.r}>
										私信主播
									</div>
								</div>
								<div className={styles.insideItemContent}>
									<div className={styles.tit}>
										岗位福利:
									</div>
									<div className={styles.text}>
									优质流量扶持+50%礼物分成+远程线上办公<br />(无时间地点限制)
									</div>
									<div className={styles.tit}>
										岗位介绍及要求:
									</div>
									<ul className={styles.ullist}>
										<li>
											在软件上和用户交友聊天。
										</li>
										<li>
											按促成会员充值与话题质量得到优厚打赏提成。
										</li>
										<li>
											喜欢与人互动交流，唠嗑聊天、交友均可。
										</li>
										<li>
											时间自由，无经验者可免费培训，越勤奋，收入越高。
										</li>
									</ul>
								</div>
							</div>
							<div className={styles.signView}>
								<div className={styles.text}>
									<div className={styles.text_}>
										平台合作主播可获得白名单特权
									</div>
									<div className={styles.text_}>
										额外享有100即可提现的门槛机会
									</div>
								</div>
								<div className={styles.txtlist}>
									<div>
										高薪诚聘
									</div>
									<div>
										福利拉满
									</div>
									<div>
										寻同行人
									</div>
									<div>
										接受小白
									</div>
								</div>
								<div className={styles.signbtn}>
									<div className={styles.btn_} onClick={sign}>
										点击联系官方签约
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </DocumentTitle>
    )
}
export default AnchorRecruitment;