import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
} from "react-router-dom";

import './index.css';
import RoutesList from './router'
// import Vconsole from 'vconsole';
// new Vconsole()
/**
 * @see react-router-dom 路由文档 https://reactrouter.com/docs/en/v6/hooks/use-routes
 * @see mobile.ant.design ui库 https://mobile.ant.design/zh/components/swiper
 * @see umi-request 网络请求库 https://github.com/umijs/umi-request/blob/master/README_zh-CN.md
 * */ 
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.Fragment>
    <Router>
      <RoutesList />
    </Router>
  </React.Fragment>
);
