import React from "react";
import DocumentTitle from "react-document-title";
import styles from './styles.module.less'
const Rule = () => {
    return (
        <DocumentTitle title="邀请聊友规则说明">
            <div className={styles.RuleControl}>
                <div className={styles.title}>参与本次活动前，请您仔细阅读活动规则。</div>
                <div className={styles.text}>分享链接邀请</div>
                <p>1、分享本页面至朋友圈或发送给微信好友；</p>
                <p>2、好友通过链接下载天聊app且注册登录；</p>
                <p>3、邀请聊友成功后随机可得18~518聊币红包奖励；</p>
                <div className={styles.text}>分享链接邀请</div>
                <p>1、如果用户直接在应用市场下载安装，没有通过邀请页面点击下载app时，绑定不到邀请人，邀请人就无法获得红包奖励。</p>
            </div>
        </DocumentTitle>
    )
}
export default Rule;