import React from "react"
import styles from './styles.module.less'
export interface StatusSwitchProps {
    style?: Object,

}

const StatusSwitch: React.FC<StatusSwitchProps> = (props) => {
    const { style } = props

    return (
        <div className={styles.StatusSwitch} style={style}>
            StatusSwitch
        </div>
    )
}

StatusSwitch.defaultProps = {}

export default StatusSwitch