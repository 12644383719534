


import React, { useState } from "react";
import { Mask,Image, DotLoading } from "antd-mobile"
import CanvasPoster from 'react-canvas-poster';
import styles from './styles.module.less'

interface ModalProps {
    visible: Boolean, 
    sender: String,
    receiver: String,
    posterClose?: Function
}

const Poster: React.FC<ModalProps> = (props: any) => {
    const { visible, posterClose, sender, receiver } = props
    const [baseurl,setbaseurl] = useState<any>('')
    const onsuccess = (src)=>{ 
        setbaseurl(src)
    }
    const drawData =  {
        width: 540,
        height: 1022,
        scale: 2, // 处理模糊问题
        backgroundColor: 'transparent',
        views: [
            // 绘制本地图片
            {
            type: 'image',
            url: require('../../assets/cpSucess.png'), // 远程图片可以直接改为图片地址
            top: 0,
            left: 0,
            width: 540,
            height: 1022,
            },
            {
                type: 'image',
                url: require('../../assets/cpSucessCircle2.png'), // 远程图片可以直接改为图片地址
                top: 402,
                left: 78,
                width: 204,
                height: 204,
            },
            {
                type: 'image',
                // url: 'https://file-tianliao-dev.oss-cn-shenzhen.aliyuncs.com/cover_avatar_img/2024/06/20/1718875605817.png', // 远程图片可以直接改为图片地址
                url: sender + '?' + new Date().getTime(),
                top: 412,
                left: 88,
                width: 184,
                height: 184,
                borderRadius: 184,
            },
            {
                type: 'image',
                url: require('../../assets/cpSucessCircle1.png'), // 远程图片可以直接改为图片地址
                top: 402,
                left: 250,
                width: 204,
                height: 204,
            },
            {
                type: 'image',
                // url: 'https://file-tianliao-dev.oss-cn-shenzhen.aliyuncs.com/cover_avatar_img/2024/06/20/1718871178788.png', // 远程图片可以直接改为图片地址
                url: receiver + '?' + new Date().getTime(),
                top: 412,
                left: 261,
                width: 184,
                height: 184,
                borderRadius: 184,
            }
        ]
     }
     const isiOS = () => {
        let u = navigator.userAgent;
        let iOs = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端\
        return iOs;
    }

    const share = ()=>{
        posterClose(false)
        // window.android.shareAndroidBaseurl(baseurl)
        if (isiOS()) {
            window.webkit.messageHandlers.shareCpBaseurl.postMessage(baseurl);
        }else{
            window.android.shareCpBaseurl(baseurl)
        }
    }
    return (
        <Mask visible={visible} onMaskClick={() => posterClose(!visible)}>

            <div className={styles.overlayContent}>
                {/* overlayContent */}
                <CanvasPoster drawData={drawData} success={onsuccess}/>
                {
                    baseurl && (
                        <div className={styles.posterView}>
                            <Image className={styles.cavasImg} src={baseurl} alt="" />
                            <Image className={styles.posterClose} src={require('../../assets/posterClose.png')} alt="" onClick={() => posterClose(false)} />
                        </div>
                    )
                }
                {
                    !baseurl && (
                        <div className={styles.loading} style={{ fontSize: 38, color: '#409EFF' }}>
                            <DotLoading color='currentColor' />
                        </div>
                    )
                }
                <div className={styles.share} onClick={share}>分享</div>
            </div>



        </Mask>
    )
}
export default Poster