import React, { useState } from "react";
import DocumentTitle from 'react-document-title'
import { isiOS , isWeixin, isIpad } from '../../utils'
import WxTip from '../../components/WxTip'
import { getdownload } from '../../services'
import styles from './styles.module.less'

const TianliaoShare = () => {
    const [showTip,setshowTip] = useState<boolean>(false)
    // 分解url参数
    const paramsString = window.location.search	
	const searchParams = new URLSearchParams(paramsString);
    // url参数
	const um_rtc = searchParams.getAll("um_rtc")
	const invite_code = searchParams.getAll("invite_code")
    const huaweiUrl = 'appmarket://details?id=com.tianliao.android'
    const oppoUrl = 'market://details?id=com.tianliao.android'
    const vivoUrl = 'vivomarket://details?id=com.tianliao.android'
    const xiaomiUrl = 'mimarket://details?id=com.tianliao.android'
    // const honorUrl = 'intent://details?id=com.tianliao.android#Intent;package=com.huawei.appmarket;scheme=market;end;'
    const honorUrl = 'appmarket://details?id=com.tianliao.android'
    // const getUserInfo = async () => {
    //     await getPersonalDetailsByInviteCode(invite_code[0])
    // }
    // 友盟JS SDK接入
    ULink([{
        id: process.env.UM_LINKID,
        data: {
            um_rtc: um_rtc[0],
            inviteCode: invite_code[0],
        }
    }]);
    const verifyBrand = () => {
        const userAgent = navigator.userAgent.toLowerCase()
        // console.log('有什么可以判断', userAgent)
        const isHuawei = userAgent.match(/huawei/i);
        const isHonor = userAgent.match(/honor/i);
        const isOppo = userAgent.match(/oppo/i);
        const isOppoR15 = userAgent.match(/PACM00/i);
        const heytapbrowser = userAgent.match(/heytapbrowser/i) // 貌似oppo
        const isVivo = userAgent.match(/vivo/i);
        const isXiaomi = userAgent.match(/mi\s/i);
        const isXIAOMI = userAgent.match(/xiaomi/i);
        const isXiaomi2s = userAgent.match(/mix\s/i);
        const isRedmi = userAgent.match(/redmi/i);
     
        if (isHuawei) {
            return 'huawei';
        } else if (isOppo || isOppoR15 || heytapbrowser) {
            return 'oppo';
        } else if (isVivo) {
            return 'vivo';
        } else if (isXiaomi || isRedmi || isXiaomi2s || isXIAOMI) {
            return 'xiaomi';
        } else if (isHonor) {
            return 'honor'
        } else {
            return 'other'
        }
    }
    const downClick = async()=>{
        if(isWeixin()){
            verifyBrand()
            setshowTip(true)
        }else{
            console.log(isiOS() , isIpad())
            if(isiOS() || isIpad()){
                window.location.href = "https://apps.apple.com/cn/app/%E5%A4%A9%E8%81%8A/id1580695049";
            }else{
                
                console.log('看看什么设备', verifyBrand())
                switch (verifyBrand()) {
                    case 'huawei':
                        window.location.href = huaweiUrl
                        break
                    case 'oppo':
                        window.location.href = oppoUrl
                        break
                    case 'vivo':
                        window.location.href = vivoUrl
                        break
                    case 'xiaomi':
                        window.location.href = xiaomiUrl
                        break
                    case 'honor':
                        window.location.href = honorUrl
                        break    
                    default: 
                        let res: any =  await getdownload()
                        if(res.code === 200){
                            window.location.href = res.data
                        }
                }
            }
        }
    }
    return (
        <DocumentTitle title='来自好友的分享'>
            <div className={styles.TianliaoShare}>
                <div className={styles.content}>
                    <div className={styles.logo}>
                        <img src={require('../../assets/newLogo.png')} alt=""/>
                        <span>天聊APP</span>
                    </div>
                    <div className={styles.icon}>
                        <img src={require('../../assets/sharecentericon.png')} alt=""/>
                    </div>
                    <div className={styles.text}>
                        <span>一个纯净版的社交聊天软件</span>
                        <span>每天超过<span className={styles.gold}>100,000,00</span>人在这里组局聊天</span>
                    </div>
                    <div className={styles.btnView}>
                        <div onClick={() => downClick()} className={styles.btn}></div>
                    </div>
                </div>
                <WxTip showTip={showTip}/>
            </div>
        </DocumentTitle>
    )
}

export default TianliaoShare;