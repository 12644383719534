/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Swiper, Image } from 'antd-mobile'
import DocumentTitle from 'react-document-title'
import { shareFriendsRedPacketReceiveRecord, listInvitationUsersForCarousel, shareFriendsRedPacketReceiveRecordTotal } from "../../services";
import { useSearchParams } from "react-router-dom";
// import CanvasPoster from 'react-canvas-poster';
import CanvasPoster from '../../components/reactCanvasPoster'
import styles from './styles.module.less'


interface Props {

}

export interface android {
 
  }

const InviteChatFriend: React.FC<Props> = () => {
    const isiOS = () => {
        let u = navigator.userAgent;
        let iOs = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端\
        return iOs;
    }
    const [posterVisible, setPosterVisible] = useState<boolean>(false)
    // const drawData = {
    //     width: 540,
    //     height: 1022,
    //     scale: 2, // 处理模糊问题
    //     views: [
    //       // 绘制本地图片
    //         {
    //             type: 'image',
    //             url: require('../../assets/InviteChatFriendPoster.png'), // 远程图片可以直接改为图片地址
    //             top: 0,
    //             left: 0,
    //             width: 540,
    //             height: 1022,
    //         },
    //         // {
    //         //     type: 'image',
    //         //     url: require('../../assets/InviteChatFriendCode.png'),
    //         //     top: 700,
    //         //     left: 195,
    //         //     width: 156,
    //         //     height: 156,
    //         // },
    //         {
    //             type: 'qcode',
    //             text: 'http://dev.h5.itianliao.com/TianliaoShare?um_rtc=5a9f2f50a7c37a5da72ef9fbb358d73b&invite_code=8M63A4',
    //             top: 700,
    //             left: 195,
    //             width: 156,
    //             height: 156,
    //             padding: 4
    //         }
    //     ]
    // }
    const [drawData, setDrawData] = useState<any>({})
    const [carouselUser, usecarouselUser] = useState<any>([]);
    const [searchParams] = useSearchParams();
    const [pageTotal, setpageTotal] = useState<number>(0);
    const [totalData, setTotalData] = useState<any>({})
    const scrollRef = useRef<any>();

    const [inviteUser, setinviteUser] = useState<any>([])
    const ListRef = useRef({
        currentPage: 1,
        pageSize: 10,
        total: 0
    })
    const onsuccess = (src)=>{ 
        console.log('url', src)
        setPosterVisible(false)
        if (isiOS()) {
            window.webkit.messageHandlers.sendBaseurl.postMessage(src)
        } else {
            window.android.sendBaseurl(src)
        }
    }
    const ellipsis = (value, len) => {
        if (!value) return ''
        if (value.length > len) {
            return value.slice(0, len) + '...'
        }
        return value
    }
    const items = carouselUser.map((item: any, index: number) => (
        <Swiper.Item key={index}>
            <div className={styles.content}>
                <Image className={styles.icon} src={require('../../assets/laba2.png')} />
                <div className={styles.contentBox}>
                    <div className={styles.Text}><span className={styles.nametxt}>{ellipsis(item.nickname, 5)}</span>邀请好友注册获得<span className={styles.Color}>{item.liaoMoney}聊币</span></div>
                </div>
            </div>
        </Swiper.Item>
    ))

    const getdataSource = async () => {
        const userId = searchParams.get("uid");
        if (!userId) return;
        const { current } = ListRef
        const res: any = await shareFriendsRedPacketReceiveRecord(userId, current.currentPage, current.pageSize)
        if (res.code === 200) {
            let dataSource: any = [...inviteUser];
            // eslint-disable-next-line array-callback-return
            res.data.map((item: any) => {
                const obj = item;
                dataSource.push(obj)
            })

            ListRef.current.currentPage = ListRef.current.currentPage + 1
            setpageTotal(res.pageTotal)
            setinviteUser(dataSource)
        }
    }

    const getTotal = async () => {
        const userId = searchParams.get("uid");
        const res = await shareFriendsRedPacketReceiveRecordTotal({userId})
        if (res.code === 200) {
            setTotalData(res.data)
        }
    }

    useEffect(() => {
        window.openPoster = (data: any) => {
			console.log('给原生的方法-------++++', data)
            setDrawData({
                width: 540,
                height: 1022,
                scale: 2, // 处理模糊问题
                views: [
                  // 绘制本地图片
                    {
                        type: 'image',
                        url: require('../../assets/InviteChatFriendPoster.png'), // 远程图片可以直接改为图片地址
                        top: 0,
                        left: 0,
                        width: 540,
                        height: 1022,
                    },
                    {
                        type: 'qcode',
                        text: data,
                        top: 700,
                        left: 195,
                        width: 156,
                        height: 156,
                        padding: 4
                    }
                ]
            })
            setPosterVisible(true)
		}
        getdataSource()
        getlistInvitationUsersForCarousel()
        getTotal()
        return () => {
			delete window.openPoster
		}
    }, [])

    const getlistInvitationUsersForCarousel = async () => {
        const userId = searchParams.get("uid");
        const res: any = await listInvitationUsersForCarousel(userId);
        if (res.code === 200) {
            usecarouselUser(res.data.data)
        }
    }

    const onScrollCapture = () => {
        const { scrollTop, clientHeight, scrollHeight } = scrollRef.current
        console.log('woc', scrollHeight - parseInt(scrollTop), clientHeight)
        if ((scrollHeight - parseInt(scrollTop) === clientHeight) || (scrollHeight - parseInt(scrollTop) === clientHeight + 1) || (scrollHeight - parseInt(scrollTop) === clientHeight - 1)) { // 有空再理一下逻辑
            if (ListRef.current.currentPage > pageTotal) return
            getdataSource()
        }

        // const scrollTop = scrollRef.current.scrollTop;

        // const clientHeight = scrollRef.current.clientHeight;
        // const scrollHeight = scrollRef.current.scrollHeight;
        // const isBottom = scrollTop + clientHeight === scrollHeight;
        // if (isBottom && scrollTop) {
        //     console.log(ListRef.current.currentPage, pageTotal)
        //     
        //     getdataSource()

        // }
    }

    const sliceTime = (time) => {
        const date = new Date()
        const year = date.getFullYear()
        if (time.slice(0,4) < year) return time.slice(0,11)
        return time.slice(5,11)
    }
    const isAndroid = () => {
        const u = navigator.userAgent;
        const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        //let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        return isAndroid;
    }


    const backFun = () => {
        if (isAndroid()) {
            android.callBack()
        } else {
            window.webkit.messageHandlers.callBack.postMessage({ 'param': '' });
        }
    }
    const ruleFun = () => {
        if (isAndroid()) {
   
            android.openRulePage();
        } else {
          
            window.webkit.messageHandlers.openRulePage.postMessage({ 'param': '' });
        }
    }
    const share = () => {
        if (isAndroid()) {
   
            window.android.openShare();
        } else {
          
            window.webkit.messageHandlers.openShare.postMessage({ 'param': '' });
        }
    }
    return (
        <DocumentTitle title='邀请聊友赚聊币'>
            <div className={styles.inviteBody}>
                <div className={styles.explainIcon} onClick={ruleFun}></div>
                <div className={styles.invitebtn} onClick={share}></div>
                <div className={styles.bg}>
                    {/* 头部 */}
                    <div className={styles.head} style={ isAndroid() ? {
                        paddingTop: '44px'
                    } : {}}  >
                        <div className={styles.backBox} onClick={backFun}>
                            <Image className={styles.back} src={require('../../assets/back.png')} />
                        </div>
                        <div className={styles.title}>推荐给聊友赚聊币</div>
                    </div>
                    {/* 走马灯 */}
                    <div className={styles.SwiperControl}>
                        <Swiper
                            className={styles.Swiper}
                            loop
                            autoplay
                            direction='vertical'
                            indicator={() => null}
                            allowTouchMove={false}
                        >{items}</Swiper>
                    </div>
                </div>
                <div className={styles.shareBox}>
                    <div className={styles.shareView}>
                        <div className={styles.dataList}>
                            <div className={styles.item}>今日注册好友：{totalData.todayUserCount || 0} 人</div>
                            <div className={styles.item}>累计邀请：{totalData.totalUserCount || 0} 人</div>
                            <div className={styles.item}>今日奖励：{totalData.todayLiaoMoney || 0} 聊币</div>
                            <div className={styles.item}>累计奖励：{totalData.totalLiaoMoney || 0} 聊币</div>
                        </div>
                        <div className={styles.userList}>
                            {
                                inviteUser.length !== 0 && <div className={styles.scrollView} onScrollCapture={() => onScrollCapture()} ref={c => scrollRef.current = c}>
                                    {
                                        inviteUser.map((item:any,index:number) => (
                                            <div className={styles.userListItem} key={item.id}>
                                                <div className={styles.left}>
                                                    <div className={styles.index}>{index+1}</div>
                                                    <Image className={styles.userImg} src={item.inviteeAvatarImg} />
                                                    <div className={styles.text}>
                                                        <div className={styles.s1}>{item.inviteeNickname}</div>
                                                        <div>{sliceTime(item.createTime)}</div>
                                                    </div>
                                                </div>
                                                <div className={styles.right}>
                                                    <div>{item.status === 1 ? '待领取' : item.status === 2 ? '奖励' : '已过期'}</div>
                                                    <div className={styles.money}>{
                                                        item.status === 2 ? item.liaoMoney+'币' : (<span className={styles.unline}></span>)
                                                    }</div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                inviteUser.length === 0 && <div className={styles.empty}>
                                    <div className={styles.text}>
                                        <span>快去推荐给微信好友，获取聊币奖励吧~</span>
                                        <span>分享10人以上，获得更大红包惊喜</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div> 
                </div>
                {/* 第二部分 */}
                {/* <div className={styles.foot}>
                    <div className={styles.share1}>
                        <span className={styles.line}></span>
                        <span className={styles.shareText}>我邀请的聊友({inviteUser.length})</span>
                        <span className={styles.line2}></span>
                    </div>

                    <div className={styles.userList}>

                        {
                            inviteUser.length !== 0 && inviteUser.map((item: any) => (
                                <div className={styles.userListItem} key={item.id}>
                                    <div className={styles.left}>
                                        <Image className={styles.userImg} src={item.avatarImg} />
                                        <div className={styles.userInfo}>
                                            <div className={styles.userName}>{item.nickname}</div>
                                            <div className={styles.userMsg}>你邀请了ta</div>
                                        </div>
                                    </div>
                                    <div className={styles.right}>
                                        <div className={styles.rightTop}>+{item.liaoMoney}币</div>
                                        <div className={styles.rightbuton}>{item.createTime.substring(0, 10)}</div>
                                    </div>

                                </div>
                            ))
                        }

                        {
                            inviteUser.length === 0 && <div className={styles.empty}>
                                <Image className={styles.emptyImg} src={require('../../assets/empty.png')} />
                                <div className={styles.emptyText}> 空空如也...</div>
                            </div>
                        }

                    </div>

                </div> */}
                {
                    posterVisible ? <CanvasPoster drawData={drawData} success={onsuccess}/> : null
                }
            </div>
        </DocumentTitle>
    )
}
export default InviteChatFriend;

