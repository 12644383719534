/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from "react";
import {getOnlineNum} from '../../services/index'

const OnlineNum = () => {
    const timerRef = useRef<any>({})
    const [count, setCount] = useState(0)

    const getonline = async()=>{
        const res:any = await getOnlineNum()
        console.log(res)
        if(res.code === 200){
            setCount(res.data.num)
        }

        
    }
    useEffect(() => {
        getonline()
        timerRef.current = setInterval(() => {
            getonline()
        }, 2000)
        return () => clearInterval(timerRef.current)
    }, [])


    return (
        <div style={{
            padding: '20px',
            fontSize: '16px'
        }}>
            当前app在线人数：{count}
        </div>
    )
}
export default OnlineNum;